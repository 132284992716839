import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as routes from '../../constants/routes';

import { LoginContainer } from '../Login';
import { PasswordResetContainer } from '../PasswordReset';

const NonAuthRouting: React.FC = () => {
  return (
    <Switch>
      <Route exact={true} path={routes.ROOT} component={LoginContainer} />
      <Route exact={true} path={routes.RESET_PASSWORD} component={PasswordResetContainer} />
      <Route component={() => <Redirect to={routes.ROOT} />} />
    </Switch>
  );
};

export default NonAuthRouting;
