import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { FormattedMessage } from 'react-intl';
import moment, { Moment } from 'moment';
import Typography from '@material-ui/core/Typography';

import { NotificationForm } from './';
import { withFirebaseLoader } from '../Loader';
import { withAuthorization } from '../Session';
import { Back } from '../common/Buttons';
import { MobileHeader } from '../common/Headers';
import { MobileContent, MobileTitle } from '../common/Layouts';

import { AppState } from '../../redux/reducers/interfaces';
import { AuthConditionProps, AuthInjectedProps } from '../Session/interfaces';
import { RemoteUserNotification, UserNotification } from './interfaces';
import { ValidationErrors } from '../../helpers/interfaces';
import { OrderedRemoteField } from '../Fields/interfaces';
import { OrderedRemoteSubstance } from '../Substances/interfaces';

import { ROOT } from '../../constants/routes';
import { naturalSort } from '../../helpers/sort';
import { translateFirebaseError } from '../../helpers/errors';
import { getUserLocale } from '../../translations';
import { validate } from '../../helpers/validation';
import validationRules from './notificationRules';
import { getCurrentISODateTime } from '../../helpers/dateTime';
import { UserRole } from '../../constants/enums';

const USER_LOCALE = getUserLocale();

interface Props {
  fields: OrderedRemoteField[];
  substances: OrderedRemoteSubstance[];
  notification: RemoteUserNotification;
  uid: string;
  firebase: {
    update: (path: string, data: object) => Promise<object>
  };
}

interface State {
  globalError?: string;
  inputs: UserNotification;
  submitted: boolean;
  validationErrors?: ValidationErrors;
}

interface RouteParams {
  id: string;
}

class EditNotificationContainer extends React.Component<Props & AuthInjectedProps<RouteParams>> {
  readonly state: State = {
    inputs: {
      activityDate: '',
      activityTime: '',
      comments: '',
      dateTime: '',
      field: '',
      substance: '',
      targetCrop: '',
      treatmentComments: ''
    },
    submitted: false
  };

  componentDidMount = () => {
    const { dateTime, targetCrop, treatmentComments } = this.props.notification;

    this.setState({
      inputs: {
        ...this.props.notification,
        activityDate: dateTime,
        activityTime: moment(dateTime).format('HH:mm'),
        targetCrop: targetCrop || '',
        treatmentComments: treatmentComments || ''
      }
    });

    if (!this.props.notification || this.props.notification.isArchived) {
      this.props.history.push(ROOT);
    }
  }

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [name]: event.target.value
      }
    });
  }

  handleDateTimeChange = (name: string) => (dateTime: Moment) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [name]: dateTime.toISOString()
      }
    });
  }

  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    this.setState({ globalError: undefined, validationErrors: undefined });
    const validationErrors = validate(this.state.inputs, validationRules);

    if (Object.keys(validationErrors).length) {
      this.setState({ validationErrors });
    }
    else {
      const { uid, match: { params: { id } } } = this.props;

      const { inputs: { activityDate, activityTime, comments, field, substance, targetCrop, treatmentComments } } = this.state;
      const time = activityTime && activityTime.split(':') || [0, 0];
      const dateTime = moment(activityDate).set({h: Number(time[0]), m: Number(time[1]), s: 0}).toISOString();

      const data: Partial<RemoteUserNotification> = {
        comments,
        dateTime,
        field,
        substance,
        targetCrop,
        treatmentComments,
        modifiedAt: getCurrentISODateTime()
      };
      try {
        await this.props.firebase.update(`/notifications/${uid}/${id}`, data);
        this.setState({ submitted: true });
      }
      catch (e) {
        console.error(e);
        this.setState({ globalError: translateFirebaseError(e) });
      }
    }
  }

  render() {
    const { fields, substances } = this.props;
    const { globalError, inputs, submitted, validationErrors } = this.state;

    const filteredFields = fields.filter((field: OrderedRemoteField) => !field.value.isArchived || inputs.field === field.key);
    const filteredFieldNames = filteredFields.map((field: OrderedRemoteField) => field.value.name[USER_LOCALE]);
    const sortedFieldIndices = naturalSort(filteredFieldNames);
    const sortedFilteredFields = sortedFieldIndices.map((idx: number) => filteredFields[idx]);

    const filteredSubstances = substances.filter((substance: OrderedRemoteSubstance) => !substance.value.isArchived || inputs.substance === substance.key).sort((a: OrderedRemoteSubstance, b: OrderedRemoteSubstance) => {
      if (a.value.name[USER_LOCALE] < b.value.name[USER_LOCALE]) return -1;
      if (a.value.name[USER_LOCALE] > b.value.name[USER_LOCALE]) return 1;
      return 0;
    });

    return (
      <div className="EditNotificationContainer">
        <MobileHeader withBackground={true} variant={submitted ? 'padded' : undefined} />
        <MobileContent>
          {submitted ? (
            <>
            <MobileTitle>
              <FormattedMessage id="notifications.success.title" />
            </MobileTitle>
            <Typography paragraph={true}>
              <FormattedMessage id="notifications.modified" />
            </Typography>
            </>
          ) : (
            <>
              <MobileTitle withLogout={true}>
                <FormattedMessage id="notifications.edit.title" />
              </MobileTitle>
              <NotificationForm
                inputOptions={{fields: sortedFilteredFields, substances: filteredSubstances}}
                inputs={inputs}
                validationErrors={validationErrors}
                handleChange={(this.handleChange)}
                handleDateTimeChange={this.handleDateTimeChange}
                handleSubmit={this.handleSubmit}
              />
              {globalError && <FormattedMessage id={globalError} />}
            </>
          )}
          <Back to={ROOT}>
            <FormattedMessage id="button.backToHome.label" />
          </Back>
        </MobileContent>
      </div>
    );
  }
}

const authCondition = (authUser: AuthConditionProps) => Object.keys(authUser.profile.roles).includes(UserRole.USER);
const loaderEntities = ['fields', 'notification', 'substances'];

const firebaseQuery = (props: Props & AuthInjectedProps<RouteParams>) => {
  const notificationId = props.match.params.id;
  return [
    { path: 'fields' },
    { path: 'substances' },
    { path: `notifications/${props.uid}/${notificationId}`, storeAs: 'notification' }
  ];
};

const mapStateToProps = (state: AppState) => {
  return {
    fields: state.firebase.ordered.fields,
    notification: state.firebase.data.notification,
    substances: state.firebase.ordered.substances,
    uid: state.firebase.auth.uid
  };
};

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps),
  firebaseConnect(firebaseQuery),
  withFirebaseLoader(loaderEntities)
)(EditNotificationContainer);
