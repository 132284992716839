import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/icons/SaveAlt';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';
import XLSX from 'xlsx';

import { SelectorRemoteNotification } from './interfaces';

interface Props {
  dataset: SelectorRemoteNotification[];
  timezone: string;
}

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing.unit
    },
    tooltip: {
      fontSize: theme.typography.pxToRem(12)
    }
  });

class AdminNotificationExport extends React.Component<Props & InjectedIntlProps & WithStyles> {
  exportFile = () => {
    const { intl, timezone } = this.props;
    const wsCols = [
      {wch: 20},
      {wch: 30},
      {wch: 20},
      {wch: 30},
      {wch: 20},
      {wch: 20},
      {wch: 30},
      {wch: 50},
      {wch: 30},
      {wch: 20},
      {wch: 50}
    ];

    const colHeader = [
      intl.formatMessage({id: 'adminNotifications.export.header.status'}),
      intl.formatMessage({id: 'adminNotifications.export.header.submittedDate'}),
      intl.formatMessage({id: 'adminNotifications.export.header.submittedTime'}),
      intl.formatMessage({id: 'adminNotifications.export.header.user'}),
      intl.formatMessage({id: 'adminNotifications.export.header.field'}),
      intl.formatMessage({id: 'adminNotifications.export.header.targetCrop'}),
      intl.formatMessage({id: 'adminNotifications.export.header.substance'}),
      intl.formatMessage({id: 'adminNotifications.export.header.comments'}),
      intl.formatMessage({id: 'adminNotifications.export.header.date'}),
      intl.formatMessage({id: 'adminNotifications.export.header.time'}),
      intl.formatMessage({id: 'adminNotifications.export.header.treatmentComments'})
    ];

    const data = this.props.dataset.reduce((acc: any, entity: SelectorRemoteNotification) => {
      const { archivedAt, createdAt, comments, date, time, field, isArchived, modifiedAt, substance, submittedBy, targetCrop, treatmentComments } = entity.value;
      const momentTime = moment.tz(time, timezone);
      const createdAtDate = moment(createdAt).format(intl.formatMessage({id: 'app.date.format'}));
      const createdAtTime = moment.tz(createdAt, timezone);
      const modifiedAtDate = moment(modifiedAt).format(intl.formatMessage({id: 'app.date.format'}));
      const modifiedAtTime = moment.tz(modifiedAt, timezone);
      const archivedAtDate = moment(archivedAt).format(intl.formatMessage({id: 'app.date.format'}));
      const archivedAtTime = moment.tz(archivedAt, timezone);

      let status;
      let submittedTime;
      let submittedDate;
      if (isArchived) {
        status = intl.formatMessage({id: 'notifications.status.cancelled'});
        submittedTime = archivedAtTime;
        submittedDate = archivedAtDate;
      }
      else if (modifiedAt) {
        status = intl.formatMessage({id: 'notifications.status.modified'});
        submittedTime = modifiedAtTime;
        submittedDate = modifiedAtDate;
      }
      else {
        status = intl.formatMessage({id: 'notifications.status.new'});
        submittedTime = createdAtTime;
        submittedDate = createdAtDate;
      }

      return [
        ...acc,
        [
          status,
          submittedDate,
          `${submittedTime.format(intl.formatMessage({id: 'app.time.format'}))} (${momentTime.zoneAbbr()})`,
          submittedBy,
          field,
          targetCrop,
          substance,
          comments,
          date.format(intl.formatMessage({id: 'app.date.format'})),
          `${momentTime.format(intl.formatMessage({id: 'app.time.format'}))} (${momentTime.zoneAbbr()})`,
          treatmentComments
        ]
      ];
    }, [colHeader]);

    const ws = XLSX.utils.aoa_to_sheet(data);
    ws['!cols'] = wsCols;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, intl.formatMessage({id: 'adminNotifications.dataTable.export.sheetName'}));
    XLSX.writeFile(wb, intl.formatMessage({id: 'adminNotifications.dataTable.export.fileName'}));
  }

  render() {
    const { classes } = this.props;
    return (
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        title={<FormattedMessage id="adminNotifications.dataTable.export.tooltip" />}
      >
        <Button type="button" variant="contained" color="primary" onClick={this.exportFile}>
          <Icon className={classes.icon} />
          <FormattedMessage id="adminNotifications.dataTable.export.button" />
        </Button>
      </Tooltip>
    );
  }
}

export default injectIntl(withStyles(styles)(AdminNotificationExport));
