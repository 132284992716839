export default {
  en: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  fr: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
};
