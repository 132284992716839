import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { AuthConditionProps, AuthInjectedProps } from './interfaces';
import { AppState } from '../../redux/reducers/interfaces';

import * as routes from '../../constants/routes';
import { AuthContext } from '../../constants/enums';

type AuthCondition = (userProfile: AuthConditionProps) => boolean;

const withAuthorization = (authCondition: AuthCondition, context: string = AuthContext.PAGE) => <WrappedProps extends AuthInjectedProps>(Component: React.ComponentType<WrappedProps>) => {
  class WithAuthorization extends React.PureComponent<WrappedProps & AuthInjectedProps> {
    componentDidMount() {
      if (context === AuthContext.PAGE && !authCondition(populateUserProfile(this.props))) {
        this.props.history.push(routes.ROOT);
      }
    }

    render() {
      if (context === AuthContext.COMPONENT && !authCondition(populateUserProfile(this.props))) {
        return null;
      }
      else {
        return (
          <Component {...this.props} />
        );
      }
    }
  }

  const mapStateToProps = (state: AppState) => {
    return {
      authUser: state.firebase.auth,
      authUserProfile: state.firebase.profile
    };
  };

  return compose(
    withRouter,
    connect(mapStateToProps)
  )(WithAuthorization) as React.ComponentClass;
};

const populateUserProfile = (props: AuthInjectedProps): AuthConditionProps => {
  const { authUser, authUserProfile } = props;
  return {
    profile: authUserProfile,
    user: authUser
  };
};

export default withAuthorization;
