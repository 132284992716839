import React from 'react';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    icon: {
      fontSize: 20,
      marginRight: theme.spacing.unit,
      opacity: 0.9,
    },
    message: {
      alignItems: 'center',
      display: 'flex',
    }
  });

interface Props {
  message?: string;
  open: boolean;
  handleClose: () => void;
}

const ErrorSnackbar: React.FC<Props & WithStyles> = ({ classes, handleClose, message, open }) => {
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.error}
        message={(
          <span id="client-snackbar" className={classes.message}>
            <ErrorIcon className={classes.icon} />
            {message && <FormattedMessage id={message} />}
          </span>
        )}
        action={[
          <IconButton
            key="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default withStyles(styles)(ErrorSnackbar);
