import { UserRole } from '../../constants/enums';

const farmRegEx = new RegExp(`^((?!${UserRole.ADMIN}).)*$`);

export default {
  email: {
    validators: {
      isEmail: {
        message: {
          id: 'validation.isEmail'
        }
      },
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  farm: {
    condition: {
      field: 'role',
      value: farmRegEx
    },
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  firstName: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  lastName: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  password: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      },
      isLength: {
        message: {
          id: 'validation.isLength',
          values: {
            length: '8'
          }
        },
        options: {
          max: 100,
          min: 8
        }
      }
    }
  },
  passwordConfirmation: {
    condition: {
      field: 'password',
      value: /^(?!\s*$).+/
    },
    validators: {
      matches: {
        field: 'password',
        message: {
          id: 'validation.matchesPassword'
        }
      }
    }
  },
  role: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  }
};
