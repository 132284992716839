import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserRoles } from './interfaces';

interface Props {
  roles: UserRoles;
}

const UserRoleList: React.FC<Props> = ({ roles }) => {
  return (
    <>
      {Object.keys(roles).map((role: string, index: number) => {
        return <p key={index}><FormattedMessage id={`roles.${role}.label`} /></p>;
      })}
    </>
  );
};

export default UserRoleList;
