import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { firebaseConnect, actionTypes } from 'react-redux-firebase';
import { FormattedMessage } from 'react-intl';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

import { NotificationList, NotificationItem } from './';
import { LoadMore } from '../common/Buttons';
import { MobileHeader } from '../common/Headers';
import { MobileContent, MobileTitle } from '../common/Layouts';
import { ImportantMessage } from '../ImportantMessage';
import { withFirebaseLoader } from '../Loader';
import { withAuthorization } from '../Session';
import { ConfirmationDialog } from '../common/ConfirmationDialog';

import { OrderedRemoteUserNotification } from './interfaces';
import { AuthConditionProps, AuthInjectedProps } from '../Session/interfaces';
import { AppState } from '../../redux/reducers/interfaces';

import { NOTIFICATIONS } from '../../constants/routes';
import { getUserLocale } from '../../translations';
import { selectors as notificationSelectors } from '../../redux/modules/UserNotifications';
import { getCurrentISODateTime } from '../../helpers/dateTime';
import { translateFirebaseError } from '../../helpers/errors';
import { UserRole } from '../../constants/enums';

import './UserNotifications.scss';

const QUERY_LIMIT = 5;
const USER_LOCALE = getUserLocale();
const NOTIFICATION_QUERY = (uid: string, limit: number = QUERY_LIMIT) => {
  return [
    { path: `notifications/${uid}`, queryParams: ['parsed', 'orderByChild=isArchived', 'equalTo=false'], storeAs: 'notificationCount' },
    { path: `notifications/${uid}`, queryParams: [`limitToLast=${limit}`, 'parsed', 'orderByChild=isArchived', 'equalTo=false'] }
  ];
};

interface Props {
  firebase: {
    dispatch: (action: object) => void;
    promiseEvents: (paths: object[]) => void;
    update: (path: string, data: object) => Promise<object>
  };
  motd: string;
  notificationCount: number;
  notifications: {
    [key: string]: OrderedRemoteUserNotification[];
  };
  uid: string;
}

interface State {
  archivingNotificationId: string | null;
  globalError?: string;
  loadMoreLimit: number;
}

class UserNotificationsContainer extends React.Component<Props & AuthInjectedProps, State> {
  readonly state = {
    archivingNotificationId: null,
    loadMoreLimit: QUERY_LIMIT
  };

  componentWillUnmount = () => {
    const { firebase } = this.props;
    firebase.dispatch({
      path: 'notifications',
      type: actionTypes.NO_VALUE,
    });
  }

  archiveNotification = async () => {
    const { firebase, uid } = this.props;
    const { archivingNotificationId: deletingNotificationId } = this.state;
    const data = {
      archivedAt: getCurrentISODateTime(),
      isArchived: true
    };

    try {
      await firebase.update(`/notifications/${uid}/${deletingNotificationId}`, data);
    }
    catch (e) {
      console.error(e);
      this.setState({ globalError: translateFirebaseError(e) });
    }

    this.closeArchiveDialog();
  }

  closeArchiveDialog = () => {
    this.setState({ archivingNotificationId: null });
  }

  openArchiveDialog = (notificationId: string) => {
    this.setState({ archivingNotificationId: notificationId });
  }

  loadMoreNotifications = () => {
    const { firebase, uid } = this.props;
    const { loadMoreLimit } = this.state;
    const newLimit = loadMoreLimit + QUERY_LIMIT;

    firebase.promiseEvents([NOTIFICATION_QUERY(uid, newLimit)[1]]);

    this.setState({ loadMoreLimit: newLimit });
  }

  render() {
    const { motd, notificationCount, notifications, uid } = this.props;
    const { archivingNotificationId } = this.state;

    const orderedNotifications = notifications && notifications[uid].sort((a: OrderedRemoteUserNotification, b: OrderedRemoteUserNotification) => {
      if (a.key < b.key) return 1;
      if (a.key > b.key) return -1;
      return 0;
    });

    return (
      <div className="UserNotificationsContainer">
        <MobileHeader withBackground={true} />
        {motd && <ImportantMessage message={motd} />}
        <MobileContent>
          <MobileTitle withLogout={true}>
            <FormattedMessage id="notifications.list.title" />
          </MobileTitle>
          {(orderedNotifications && orderedNotifications.length) ? (
            <>
              <NotificationList>
                {orderedNotifications.map((notification: OrderedRemoteUserNotification) => {
                  return (
                    <NotificationItem
                      key={notification.key}
                      notification={notification}
                      handleArchiving={this.archiveNotification}
                      handleArchiveClick={() => this.openArchiveDialog(notification.key)}
                    />
                  );
                })}
              </NotificationList>
              {orderedNotifications.length < notificationCount &&
                <LoadMore handleLoadMore={this.loadMoreNotifications} />
              }
            </>
          ) : (
            <Typography variant="body2">
              <FormattedMessage id="notifications.noResults" />
            </Typography>
          )}
        </MobileContent>
        <Link className="NewNotificationFab" to={NOTIFICATIONS}>
          <Fab color="primary">
            <AddIcon />
          </Fab>
        </Link>

        <ConfirmationDialog
          open={!!archivingNotificationId}
          dialogCancelLabel={<FormattedMessage id="notifications.archive.dialog.button.cancel" />}
          dialogConfirmLabel={<FormattedMessage id="notifications.archive.dialog.button.confirm" />}
          dialogMessage={<FormattedMessage id="notifications.archive.dialog.message" />}
          dialogTitle={<FormattedMessage id="notifications.archive.dialog.title" />}
          handleClose={this.closeArchiveDialog}
          handleConfirm={this.archiveNotification}
        />
      </div>
    );
  }
}

const firebaseQuery = (props: Props & AuthInjectedProps) => {
  return [
    { path: 'fields' },
    { path: `motd/${USER_LOCALE}` },
    { path: 'substances' },
    ...NOTIFICATION_QUERY(props.uid)
  ];
};

const mapStateToProps = (state: AppState) => {
  return {
    motd: state.firebase.data.motd && state.firebase.data.motd[USER_LOCALE],
    notificationCount: state.firebase.ordered.notificationCount && state.firebase.ordered.notificationCount.length || 0,
    notifications: notificationSelectors.selectUserNotifications(state, USER_LOCALE),
    uid: state.firebase.auth.uid
  };
};

const authCondition = (authUser: AuthConditionProps) => Object.keys(authUser.profile.roles).includes(UserRole.USER);
const loaderEntities = ['fields', 'motd', 'notifications', 'substances'];

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps),
  firebaseConnect(firebaseQuery),
  withFirebaseLoader(loaderEntities),
)(UserNotificationsContainer);
