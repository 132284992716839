import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { SubstanceForm } from './';
import { ActionsTableCell, DataTable, DataTableActions } from '../common/DataTable';
import { MobileTitle } from '../common/Layouts';
import { withFirebaseLoader } from '../Loader';
import { withAuthorization } from '../Session';

import { DataTableActionState, DataTableCol, DataTableOrder } from '../common/DataTable/interfaces';
import { OrderedRemoteSubstance, Substance } from './interfaces';
import { AuthConditionProps } from '../Session/interfaces';
import { AppState } from '../../redux/reducers/interfaces';
import { ValidationErrors } from '../../helpers/interfaces';

import { translateFirebaseError } from '../../helpers/errors';
import { submitDataTableEntity, natSort } from '../../helpers/dataTable';
import { validate } from '../../helpers/validation';
import validationRules from './substanceRules';
import { DataTableAction, UserRole } from '../../constants/enums';

interface Props {
  firebase: {
    push: (path: string, data: object) => Promise<object>
    update: (path: string, data: object) => Promise<object>
  };
  substances: OrderedRemoteSubstance[];
}

interface State {
  globalError?: string;
  inputs: Substance;
  validationErrors?: ValidationErrors;
}

class SubstancesContainer extends React.Component<Props, State> {
  static readonly inputsInitialState: Substance = {
    id: '',
    name: {
      en: '',
      fr: ''
    }
  };

  static readonly cols: DataTableCol[] = [
    { id: 'id', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="substances.dataTable.header.id" /> },
    { id: 'name.en', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="substances.dataTable.header.en" /> },
    { id: 'name.fr', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="substances.dataTable.header.fr" /> },
    { id: 'actions', sortable: false, numeric: false, disablePadding: false, label: <FormattedMessage id="dataTable.header.actions" /> },
  ];

  readonly state: State = {
    inputs: SubstancesContainer.inputsInitialState
  };

  closeDialog = () => {
    this.setState({
      inputs: SubstancesContainer.inputsInitialState,
      validationErrors: undefined
    });
  }

  confirmDialog = async (dataTableAction: DataTableActionState) => {
    this.setState({ globalError: undefined, validationErrors: undefined });
    const { inputs } = this.state;
    const validationErrors = validate(inputs, validationRules);
    const action = Object.keys(dataTableAction)[0];

    if ((action !== DataTableAction.ARCHIVE && action !== DataTableAction.UNARCHIVE) && Object.keys(validationErrors).length) {
      this.setState({ validationErrors });
      throw Error('validation errors');
    }
    else {
      const { firebase } = this.props;
      const options = {
        firebase,
        inputs,
        action: dataTableAction,
        entityName: 'substances',
      };

      try {
        await submitDataTableEntity<Substance>(options);
      }
      catch (e) {
        console.error(e);
        this.setState({ globalError: translateFirebaseError(e) });
        throw(e);
      }
    }
  }

  openDialog = (action: string, entityKey?: string) => (_0: React.MouseEvent<HTMLButtonElement>) => {
    const { substances } = this.props;

    if (action === DataTableAction.EDIT) {
      const substance = substances.find((s: OrderedRemoteSubstance) => s.key === entityKey);
      if (substance) {
        this.setState({ inputs: substance.value });
      }
    }
  }

  handleChange = (name: string, isTranslatableField = false) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    if (isTranslatableField) {
      this.setState({
        inputs: {
          ...this.state.inputs,
          name: {
            ...this.state.inputs.name,
            [name]: event.target.value
          }
        }
      });
    }
    else {
      this.setState({
        inputs: {
          ...this.state.inputs,
          [name]: event.target.value
        }
      });
    }
  }

  render() {
    const { substances } = this.props;
    const { inputs, validationErrors } = this.state;
    const substanceForm = () => (
      <SubstanceForm
        inputs={inputs}
        validationErrors={validationErrors}
        handleChange={this.handleChange}
      />
    );

    return (
      <div className="SubstancesContainer">
        <MobileTitle withLogout={true}>
          <FormattedMessage id="substances.page.title" />
        </MobileTitle>
        <DataTable
          addButtonLabel={<FormattedMessage id="substances.button.add" />}
          addEditForm={substanceForm}
          addDialogTitle={<FormattedMessage id={'substances.adding.dialog.title'} />}
          archiveDialogCancelLabel={<FormattedMessage id="substances.archive.dialog.button.cancel" />}
          archiveDialogConfirmLabel={<FormattedMessage id="substances.archive.dialog.button.confirm" />}
          archiveDialogMessage={<FormattedMessage id="substances.archive.dialog.message" />}
          archiveDialogTitle={<FormattedMessage id="substances.archive.dialog.title" />}
          unarchiveDialogCancelLabel={<FormattedMessage id="substances.unarchive.dialog.button.cancel" />}
          unarchiveDialogConfirmLabel={<FormattedMessage id="substances.unarchive.dialog.button.confirm" />}
          unarchiveDialogMessage={<FormattedMessage id="substances.unarchive.dialog.message" />}
          unarchiveDialogTitle={<FormattedMessage id="substances.unarchive.dialog.title" />}
          editDialogTitle={<FormattedMessage id="substances.editing.dialog.title" />}
          cols={SubstancesContainer.cols}
          orderBy="name.en"
          rowCount={substances.length}
          handleDialogClose={this.closeDialog}
          handleDialogConfirm={this.confirmDialog}
          handleDialogOpen={this.openDialog}
        >
          {(order: DataTableOrder, orderBy: string, page: number, rowsPerPage: number, openDialog: any) => {
            return (
              natSort(substances,order, orderBy)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n: OrderedRemoteSubstance) => {
                return (
                  <TableRow
                    className={n.value.isArchived ? 'TableRow-archived' : ''}
                    classes={{hover: 'TableRow-hover'}}
                    hover={true}
                    tabIndex={-1}
                    key={n.value.id}
                  >
                    <TableCell>{n.value.id}</TableCell>
                    <TableCell>{n.value.name.en}</TableCell>
                    <TableCell>{n.value.name.fr}</TableCell>
                    <ActionsTableCell>
                      <DataTableActions
                        entityKey={n.key}
                        handleDialog={openDialog}
                        isArchived={n.value.isArchived}
                      />
                    </ActionsTableCell>
                  </TableRow>
                );
              })
            );
          }}
        </DataTable>
      </div>
    );
  }
}

const firebaseQuery = (props: RouteComponentProps) => {
  const params = new URLSearchParams(props.location.search);
  const includeArchived = params.get('includeArchived') || false;
  const queryParams = includeArchived ? [] : ['parsed', 'orderByChild=isArchived', 'equalTo=false'];

  return [
    { path: 'substances', queryParams},
  ];
};

const mapStateToProps = (state: AppState) => {
  return {
    substances: state.firebase.ordered.substances || []
  };
};

const authCondition = (authUser: AuthConditionProps) => Object.keys(authUser.profile.roles).includes(UserRole.ADMIN);
const loaderEntities = ['substances'];

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps),
  firebaseConnect(firebaseQuery),
  withFirebaseLoader(loaderEntities),
)(SubstancesContainer);
