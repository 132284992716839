import React from 'react';
import classNames from 'classnames';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import { LanguageSwitch } from '../../LanguageSwitch';

import { getUserLocale, supportedLocales } from '../../../translations';

import logoEn from '../../../assets/images/logo-en.png';
import logoEn2x from '../../../assets/images/logo-en@2x.png';
import logoFr from '../../../assets/images/logo-fr.png';
import logoFr2x from '../../../assets/images/logo-fr@2x.png';
import './Headers.scss';

const LOGO = getUserLocale() === 'en' ? logoEn : logoFr;
const LOGO2x = getUserLocale() === 'en' ? logoEn2x : logoFr2x;

interface Props {
  variant?: 'padded';
  withBackground?: boolean;
}

const MobileHeader: React.FC<Props & InjectedIntlProps> = ({ intl, variant, withBackground }) => {
  const headerClasses = classNames({
    'MobileHeader': true,
    'Padded': variant === 'padded',
    'WithBackground': withBackground
  });

  return (
    <div className={headerClasses}>
      <div className="Logo">
        <img src={LOGO} srcSet={`${LOGO} 1x, ${LOGO2x} 2x`} alt={intl.formatMessage({id: 'app.logo.alt'})} />
      </div>
      <LanguageSwitch
        supportedLocales={supportedLocales}
        userLocale={getUserLocale()}
      />
    </div>
  );
};

export default injectIntl(MobileHeader);
