import React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import classNames from 'classnames';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { getUserLocale, setUserLocale } from '../../translations';

interface Props extends InjectedIntlProps {
  supportedLocales: string[];
  userLocale: string;
}

const styles = (theme: Theme) =>
  createStyles({
    button: {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      color: '#fff',
      marginLeft: 10,
      minWidth: 'auto',
      opacity: 0.6,
      padding: 0
    },
    selected: {
      opacity: 1,
    }
  });

const LanguageSwitch: React.FC<Props & WithStyles> = ({ classes, intl, supportedLocales, userLocale }) => {
  const handleChange = (locale: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserLocale(locale);
    location.reload();
  };

  return (
    <div className="LanguageSwitch">
      {supportedLocales.map((locale: string) => {
        const buttonClasses = classNames({
          [classes.button]: true,
          [classes.selected]: locale === getUserLocale()
        });

        return (
          <Button className={buttonClasses} key={locale} name={locale} onClick={handleChange(locale)}>{intl.formatMessage({id: `app.locale.${locale}`})}</Button>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(injectIntl(LanguageSwitch));
