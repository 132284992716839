import React from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';

import { EmailRecipient } from './interfaces';
import { ValidationErrors } from '../../helpers/interfaces';

interface Props {
  inputs: EmailRecipient;
  validationErrors?: ValidationErrors;
  handleChange: (inputName: string, isTranslatableField?: boolean) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const EmailRecipientForm: React.FC<Props> = ({ handleChange, inputs, validationErrors }) => {
  return (
    <div className="EmailRecipientForm">
      <TextField
        name="email"
        error={!!(validationErrors && validationErrors['email'])}
        helperText={validationErrors && validationErrors['email'] && <FormattedMessage {...validationErrors['email']} />}
        fullWidth={true}
        label={<FormattedMessage id="emailRecipients.dataTable.header.email" />}
        value={inputs.email}
        onChange={handleChange('email')}
        margin="normal"
      />
    </div>
  );
};

export default EmailRecipientForm;
