import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TextField, { FilledTextFieldProps } from '@material-ui/core/TextField';

const styles = (theme: Theme) =>
  createStyles({
    error: {
      color: '#ff9188 !important'
    },
    input: {
      '&:hover': {
        backgroundColor: '#fff',
      },
      backgroundColor: '#fff !important',
      border: 0,
      borderRadius: 0,
      paddingRight: 0
    },
    title: {
      color: '#fff'
    }
  });

class FilledTextField extends React.Component<Partial<FilledTextFieldProps> & WithStyles> {
  render() {
    const { classes, InputProps, ...rest } = this.props;
    return (
      <TextField
        variant="filled"
        FormHelperTextProps={{
          classes: {
            error: classes.error,
          }
        }}
        InputProps={{
          ...InputProps,
          classes: {
            adornedEnd: classes.input,
            input: classes.input
          }
        }}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(FilledTextField);
