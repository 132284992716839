import React from 'react';

import './Layouts.scss';

interface Props {
  children: React.ReactNode;
}

const MobileContent: React.FC<Props> = ({ children }) => {
  return (
    <div className="MobileContent">
      {children}
    </div>
  );
};

export default MobileContent;
