export default {
  'name.en': {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  'name.fr': {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  }
};
