import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  isLoading?: boolean;
  children?: React.ReactNode;
}

class CircularProgressLoader extends React.Component<Props> {
  render() {
    const { children, isLoading } = this.props;

    if (typeof isLoading === 'undefined' || isLoading === true) {
      return (
        <div className="CircularProgressLoader">
          <CircularProgress />
        </div>
      );
    }
    else {
      return (
        children
      );
    }
  }
}

export default CircularProgressLoader;
