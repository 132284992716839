export default {
  id: {
    validators: {
      isNumeric: {
        message: {
          id: 'validation.isNumeric'
        }
      }
    }
  },
  'name.en': {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  'name.fr': {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  }
};
