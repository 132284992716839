import { applyMiddleware, createStore, Middleware } from 'redux';

import { composeEnhancers } from './utils';
import rootReducer from '../reducers';

function configureStore(initialState?: object) {
  const middlewares = [] as Middleware[];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  // create store
  return createStore(rootReducer, initialState!, enhancer);
}

// pass an optional param to rehydrate state on app start
const store = configureStore();

// export store singleton instance
export default store;
