export default {
  activityDate: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      },
      isISO8601: {
        message: {
          id: 'validation.isISO8601'
        }
      }
    }
  },
  activityTime: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  comments: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  field: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  substance: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
  targetCrop: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      },
      isLength: {
        message: {
          id: 'validation.isMaxLength',
          values: {
            length: '30'
          }
        },
        options: {
          max: 30,
          min: 1
        }
      }
    }
  },
  treatmentComments: {
    validators: {
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  },
};
