export enum AuthContext {
  COMPONENT = 'component',
  PAGE = 'page'
}

export enum DataTableAction {
  ADD = 'adding',
  ARCHIVE = 'archiving',
  EDIT = 'editing',
  UNARCHIVE = 'unarchiving'
}

export enum UserRole {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
  USER = 'user'
}
