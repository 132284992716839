import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Logout } from '../../Logout';

import './Layouts.scss';

interface Props {
  children: React.ReactNode;
  withLogout?: boolean;
}

const MobileTitle: React.FC<Props> = ({ children, withLogout }) => {
  return (
    <div className="MobileTitle">
      <Typography variant="h1" color="primary" style={{fontSize: '1.3em', fontWeight: 500}}>
        {children}
      </Typography>
      {withLogout && <Logout />}
    </div>
  );
};

export default MobileTitle;
