import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { getUserLocale } from '../translations';

if (!firebase.apps.length) {
  firebase.initializeApp(require(`./configs/${process.env.REACT_APP_FIREBASE_ENV}`).default);
}

firebase.auth();
firebase.database();

firebase.auth().languageCode = getUserLocale();

const firebaseApp = firebase;
export default firebaseApp;
