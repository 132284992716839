import messages_fr from './fr.json';
import messages_en from './en.json';

interface AppMessages {
  [key: string]: {
    [key: string]: string;
  };
}

const messages: AppMessages = {
  'en': messages_en,
  'fr': messages_fr
};

const defaultLocale = 'en';
const supportedLocales = Object.keys(messages);
const getUserLocale = () => (localStorage.getItem('userLocale') || defaultLocale);
const setUserLocale = (userLocale: string) => (localStorage.setItem('userLocale', userLocale));

export {
  defaultLocale,
  getUserLocale,
  setUserLocale,
  supportedLocales
};

export default messages;
