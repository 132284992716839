import React from 'react';
import { withFirebase } from 'react-redux-firebase';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';

interface Props {
  firebase: {
    logout: () => void;
  };
}

const styles = (theme: Theme) =>
  createStyles({
    button: {
      color: '#707070',
      textTransform: 'none'
    }
  });

const Logout: React.FC<Props & WithStyles> = ({ classes, firebase }) => {
  const logout = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    firebase.logout();
  };

  return (
    <Link className={classes.button} href="#" onClick={logout} variant="button">
      <FormattedMessage id="button.logout.label" />
    </Link>
  );
};

export default withStyles(styles)(withFirebase(Logout));
