import { createSelector } from 'reselect';

import { selectors as farmSelectors } from '../Farms';
import { selectors as fieldSelectors } from '../Fields';
import { selectors as substanceSelectors } from '../Substances';
import { selectors as userSelectors } from '../Users';

import { AppState } from '../../reducers/interfaces';
import { OrderedRemoteNotification } from '../../../components/AdminNotifications/interfaces';
import { OrderedRemoteUserNotification } from '../../../components/UserNotifications/interfaces';

interface SelectUserNotification {
  [key: string]: OrderedRemoteUserNotification[];
}

const getAllNotifications = (state: AppState): SelectUserNotification => state.firebase.ordered.notifications;
const getAdminNotifications = (state: AppState): OrderedRemoteNotification[] => state.firebase.ordered.adminNotifications;

export const selectUserNotifications = createSelector(
  getAllNotifications,
  fieldSelectors.getFields,
  substanceSelectors.getSubstances,
  (_0: AppState, userLocale: string) => userLocale,
  (notifications, fields, substances, userLocale): SelectUserNotification | null => {
    if (notifications && fields && substances) {
      return Object.keys(notifications).reduce((notifAcc: object, uid: string): SelectUserNotification => {
        const ns = (notifications[uid] || []).map((n: OrderedRemoteUserNotification) => {
          return {
            ...n,
            value: {
              ...n.value,
              field: fields[n.value.field].name[userLocale],
              substance: substances[n.value.substance].name[userLocale]
            }
          };
        });

        return {
          ...notifAcc,
          [uid]: ns
        };
      }, {});
    }

    return null;
  }
);

export const selectNotifications = createSelector(
  getAdminNotifications,
  farmSelectors.getFarms,
  fieldSelectors.getFields,
  substanceSelectors.getSubstances,
  userSelectors.getUsers,
  (_0: AppState, userLocale: string, includeArchived: boolean) => ({ includeArchived, userLocale }),
  (notifications, farms, fields, substances, users, options) => {
    if (notifications && farms && fields && substances && users) {
      const { includeArchived, userLocale } = options;

      const n = notifications.map((notification: OrderedRemoteNotification) => {
        const firstName = users[notification.key].firstName;
        const lastName = users[notification.key].lastName;
        const email = users[notification.key].email;
        const farm = farms[users[notification.key].farm] && farms[users[notification.key].farm].name[userLocale] || ''

        const userNotifications = Object.keys(notification.value).map((n: any) => {
          return {
            key: n,
            value: {
              ...notification.value[n],
              email,
              farm,
              firstName,
              lastName,
              field: fields[notification.value[n].field].name[userLocale],
              submittedBy: `${lastName}, ${firstName}`,
              substance: substances[notification.value[n].substance].name[userLocale]
            }
          };
        });

        return userNotifications;

      });

      return flatten(n).filter((n: any) => includeArchived || n.value.isArchived === false);
    }

    return null;
  }
);

const flatten = (a: any) => {
  var queue = a.slice();
  var result = [];
  while(queue.length) {
      let curr = queue.pop();
      if(Array.isArray(curr)) {
          queue.push(...curr);
      }
      else result.push(curr);
  }
  return result;
}
