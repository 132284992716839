import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Fab, { FabProps } from '@material-ui/core/Fab';

interface Props {
  children: React.ReactNode;
}

const styles = (theme: Theme) =>
  createStyles({
    button: {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      backgroundColor: 'transparent',
      border: 'solid 2px #fff',
      color: '#fff'
    }
  });

class OutlineFab extends React.Component<Props & Partial<FabProps> & WithStyles> {
  render() {
    const { classes, children, ...rest } = this.props;
    return (
      <Fab className={classes.button} {...rest}>
        {children}
      </Fab>
    );
  }
}

export default withStyles(styles)(OutlineFab);
