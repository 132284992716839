import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DatePicker } from 'material-ui-pickers';
import { Moment } from 'moment';

import { NotificationFilters, NotificationFilterOptions } from './interfaces';

import './AdminNotifications.scss';

interface Props {
  filterOptions: NotificationFilterOptions;
  filters: NotificationFilters;
  handleDateTimeChange: (inputName: string) => (dateTime: Moment) => void;
  handleSelectChange: (name: string) => (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      marginRight: theme.spacing.unit,
      width: 200,
    },
    menuItem: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      }
    },
    picker: {
      display: 'inline-flex',
      marginRight: theme.spacing.unit
    },
    pickers: {
      display: 'inline-flex',
      marginRight: theme.spacing.unit * 4
    }
  });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AdminNotificationFilters: React.FC<Props & InjectedIntlProps & WithStyles> = ({
  classes, filters: { date, field, submittedBy, substance }, filterOptions, handleDateTimeChange, handleSelectChange, intl
}) => {
  return (
    <div className="AdminNotificationFilters">
      <div className={classes.pickers}>
        <DatePicker
          className={classes.picker}
          clearable={true}
          label={<FormattedMessage id="adminNotifications.dataTable.filter.date.from" />}
          format={intl.formatMessage({id: 'app.date.format'})}
          value={date.from}
          onChange={handleDateTimeChange('from')}
          cancelLabel={<FormattedMessage id="button.cancel.label" />}
          okLabel={<FormattedMessage id="button.ok.label" />}
        />
        <DatePicker
          className={classes.picker}
          clearable={true}
          label={<FormattedMessage id="adminNotifications.dataTable.filter.date.to" />}
          format={intl.formatMessage({id: 'app.date.format'})}
          value={date.to}
          onChange={handleDateTimeChange('to')}
          cancelLabel={<FormattedMessage id="button.cancel.label" />}
          okLabel={<FormattedMessage id="button.ok.label" />}
        />
      </div>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="fields-multiple">
          <FormattedMessage id="adminNotifications.dataTable.filter.fields" />
        </InputLabel>
        <Select
          multiple={true}
          value={field}
          onChange={handleSelectChange('field')}
          input={<Input id="fields-multiple" />}
          MenuProps={MenuProps}
          renderValue={(value: any) => <span>{value.join(', ')}</span>}
        >
          {filterOptions.fields.map(option => (
            <MenuItem className={classes.menuItem} key={option} value={option}>
              <Checkbox checked={field.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="substances-multiple">
          <FormattedMessage id="adminNotifications.dataTable.filter.substances" />
        </InputLabel>
        <Select
          multiple={true}
          value={substance}
          onChange={handleSelectChange('substance')}
          input={<Input id="substances-multiple" />}
          MenuProps={MenuProps}
          renderValue={(value: any) => <span>{value.join(', ')}</span>}
        >
          {filterOptions.substances.map(option => (
            <MenuItem className={classes.menuItem} key={option} value={option}>
              <Checkbox checked={substance.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="users-multiple">
          <FormattedMessage id="adminNotifications.dataTable.filter.submittedBy" />
        </InputLabel>
        <Select
          multiple={true}
          value={submittedBy}
          onChange={handleSelectChange('submittedBy')}
          input={<Input id="users-multiple" />}
          MenuProps={MenuProps}
          renderValue={(value: any) => <span>{value.join(', ')}</span>}
        >
          {filterOptions.users.map(option => (
            <MenuItem className={classes.menuItem} key={option} value={option}>
              <Checkbox checked={submittedBy.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(injectIntl(AdminNotificationFilters));
