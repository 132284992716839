import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { User } from './interfaces';
import { OrderedRemoteFarm } from '../Farms/interfaces';
import { ValidationErrors } from '../../helpers/interfaces';

import { UserRole } from '../../constants/enums';
import { getUserLocale } from '../../translations';

interface Props {
  inputOptions: {
    farms: OrderedRemoteFarm[];
  };
  inputs: User;
  isNew: boolean;
  validationErrors?: ValidationErrors;
  handleChange: (inputName: string, isTranslatableField?: boolean) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const UserForm: React.FC<Props & InjectedIntlProps> = ({ handleChange, inputOptions, inputs, isNew, intl, validationErrors }) => {
  const userLocale = getUserLocale();

  return (
    <div className="UserForm">
      <TextField
        name="email"
        error={!!(validationErrors && validationErrors['email'])}
        helperText={validationErrors && validationErrors['email'] && <FormattedMessage {...validationErrors['email']} />}
        fullWidth={true}
        label={<FormattedMessage id="users.dataTable.header.email" />}
        value={inputs.email}
        onChange={handleChange('email')}
        margin="normal"
      />

      <br /><br />
      <TextField
        name="firstName"
        error={!!(validationErrors && validationErrors['firstName'])}
        helperText={validationErrors && validationErrors['firstName'] && <FormattedMessage {...validationErrors['firstName']} />}
        fullWidth={true}
        label={<FormattedMessage id="users.dataTable.header.firstName" />}
        value={inputs.firstName}
        onChange={handleChange('firstName')}
        margin="normal"
      />

      <br /><br />
      <TextField
        name="lastName"
        error={!!(validationErrors && validationErrors['lastName'])}
        helperText={validationErrors && validationErrors['lastName'] && <FormattedMessage {...validationErrors['lastName']} />}
        fullWidth={true}
        label={<FormattedMessage id="users.dataTable.header.lastName" />}
        value={inputs.lastName}
        onChange={handleChange('lastName')}
        margin="normal"
      />

      <br /><br />
      <TextField
        name="role"
        error={!!(validationErrors && validationErrors['role'])}
        helperText={validationErrors && validationErrors['role'] && <FormattedMessage {...validationErrors['role']} />}
        select={true}
        fullWidth={true}
        label={<FormattedMessage id="users.dataTable.header.role" />}
        value={inputs.role}
        onChange={handleChange('role')}
        SelectProps={{
          native: true
        }}
        margin="normal"
      >
        <option value="" disabled={true} />
        {Object.values(UserRole).filter(role => role !== UserRole.SUPER_ADMIN).map((role: string, index: number) => (
          <option key={index} value={role}>
            {intl.formatMessage({id: `roles.${role}.label`})}
          </option>
        ))}
      </TextField>

      {inputs.role !== UserRole.ADMIN &&
        <>
          <br /><br />
          <TextField
            name="farm"
            error={!!(validationErrors && validationErrors['farm'])}
            helperText={validationErrors && validationErrors['farm'] && <FormattedMessage {...validationErrors['farm']} />}
            select={true}
            fullWidth={true}
            label={<FormattedMessage id="users.dataTable.header.farm" />}
            value={inputs.farm}
            onChange={handleChange('farm')}
            SelectProps={{
              native: true
            }}
            margin="normal"
          >
            <option value="" disabled={true} />
            {inputOptions.farms.map((option: OrderedRemoteFarm) => (
              <option key={option.key} value={option.key} disabled={option.value.isArchived}>
                {option.value.name[userLocale]}
              </option>
            ))}
          </TextField>
        </>
      }

      <br /><br />
      {!isNew &&
        <>
          <br />
          <Typography variant="subtitle2">
            <FormattedMessage id="users.form.password.subtitle" />
          </Typography>
        </>
      }
      <TextField
        name="password"
        type="password"
        error={!!(validationErrors && validationErrors['password'])}
        helperText={validationErrors && validationErrors['password'] && <FormattedMessage {...validationErrors['password']} />}
        fullWidth={true}
        label={<FormattedMessage id="users.form.password.label" />}
        value={inputs.password}
        onChange={handleChange('password')}
        margin="normal"
      />

      <br /><br />
      <TextField
        name="passwordConfirmation"
        type="password"
        error={!!(validationErrors && validationErrors['passwordConfirmation'])}
        helperText={validationErrors && validationErrors['passwordConfirmation'] && <FormattedMessage {...validationErrors['passwordConfirmation']} />}
        fullWidth={true}
        label={<FormattedMessage id="users.form.passwordConfirmation.label" />}
        value={inputs.passwordConfirmation}
        onChange={handleChange('passwordConfirmation')}
        margin="normal"
      />
    </div>
  );
};

export default injectIntl(UserForm);
