import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/icons/Announcement';

import './ImportantMessage.scss';

interface Props {
  message?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      paddingRight: 10
    },
    message: {
      alignItems: 'center',
      color: '#fff',
      display: 'flex',
      fontSize: '0.8em',
      whiteSpace: 'pre-line'
    }
  });

const ImportantMessage: React.FC<Props & WithStyles> = ({ classes, message }) => {
  return (
    <div className="ImportantMessage">
      <Typography className={classes.message} variant="caption">
        <Icon className={classes.icon} fontSize="large" /> {message}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(ImportantMessage);
