interface Error {
  message: string;
  stack?: string;
}

export const translateFirebaseError = (error: Error): string => {
  if (error.message.indexOf('PERMISSION_DENIED') > -1) {
    return 'firebase.error.permission-denied';
  }

  return 'firebase.error.firebase/unknown';
};
