import React from 'react';

import './UserNotifications.scss';

interface Props {
  children: React.ReactNode;
}

const NotificationList: React.FC<Props> = ({ children }) => {
  return (
    <ul className="NotificationList">
      {children}
    </ul>
  );
};

export default NotificationList;
