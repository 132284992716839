import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';

import { OutlineFab } from '../common/Buttons';
import { FilledTextField } from '../common/Inputs';

import { RESET_PASSWORD } from '../../constants/routes';

interface Props {
  inputs: {
    email: string;
    password: string;
  };
  showPassword: boolean;
  validationError?: {
    code: string;
    message: string;
  };
  handleChange: (inputName: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleShowPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing.unit
    },
    typography: {
      alignItems: 'center',
      color: '#fff',
      display: 'flex'
    }
  });

const LoginForm: React.FC<Props & WithStyles> = ({ classes, handleChange, handleShowPassword, handleSubmit, inputs, showPassword, validationError }) => {
  const passwordAdorment = (
    <InputAdornment position="end" style={{marginRight: 5}}>
      <IconButton
        onClick={handleShowPassword}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
  return (
    <div className="LoginForm">
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={classes.typography}>
          <FormattedMessage id="login.welcome.title" />
        </Typography>
        <FilledTextField
          name="email"
          error={!!(validationError && /email|user/.test(validationError.code))}
          helperText={!!(validationError && /email|user/.test(validationError.code)) && <FormattedMessage id={validationError.message} />}
          fullWidth={true}
          label={<FormattedMessage id="form.field.email.label" />}
          value={inputs['email']}
          onChange={handleChange('email')}
          margin="normal"
        />

        <br />
        <FilledTextField
          name="password"
          type={showPassword ? 'text' : 'password'}
          error={!!(validationError && /password/.test(validationError.code))}
          helperText={!!(validationError && /password/.test(validationError.code)) && <FormattedMessage id={validationError.message} />}
          fullWidth={true}
          label={<FormattedMessage id="form.field.password.label" />}
          value={inputs['password']}
          onChange={handleChange('password')}
          margin="normal"
          InputProps={{
            endAdornment: passwordAdorment
          }}
        />

        <br /><br />
        <OutlineFab type="submit" variant="extended" color="default">
          <FormattedMessage id="button.login.label" />
        </OutlineFab>
      </form>

      <Link className="ResetPassword" to={RESET_PASSWORD}>
        <Typography variant="subtitle1" className={classes.typography}>
          <LockIcon className={classes.icon} />
          <FormattedMessage id="login.resetPassword" />
        </Typography>
      </Link>
    </div>
  );
};

export default withStyles(styles)(LoginForm);
