import MomentUtils from '@date-io/moment';
import { Moment } from 'moment';

import messages, { defaultLocale } from '../translations';

class LocalizedUtils extends MomentUtils {
  getDateTimePickerHeaderText(date: Moment) {
    return date.format(messages[this.locale || defaultLocale]['form.field.dateTimePicker.headerFormat']);
  }
}

export default LocalizedUtils;
