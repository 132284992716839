import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Administration } from '../Administration';
import { EditNotificationContainer, NewNotificationContainer, UserNotificationsContainer } from '../UserNotifications';

import * as routes from '../../constants/routes';

interface Props {
  isAdmin: boolean;
}

const AuthRouting: React.FC<Props> = ({ isAdmin }) => {
  return (
    <Switch>
      {isAdmin ? (
        <>
          <Administration />
        </>
      ) : (
        <>
          <Route exact={true} path={routes.ROOT} component={UserNotificationsContainer} />
          <Route exact={true} path={routes.NOTIFICATIONS} component={NewNotificationContainer} />
          <Route exact={true} path={`${routes.NOTIFICATIONS}/:id`} component={EditNotificationContainer} />
        </>
      )}
      <Route component={() => <Redirect to={routes.ROOT} />} />
    </Switch>
  );
};

export default AuthRouting;
