import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

interface Props {
  children: React.ReactNode;
}

const styles = (theme: Theme) =>
  createStyles({
    cell: {
      width: 250
    }
  });

const ActionsTableCell: React.FC<Props & WithStyles> = ({ children, classes }) => {
  return (
    <TableCell className={classes.cell}>
      {children}
    </TableCell>
  );
};

export default withStyles(styles)(ActionsTableCell);
