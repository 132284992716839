import React from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';

import { Field } from './interfaces';
import { ValidationErrors } from '../../helpers/interfaces';

interface Props {
  inputs: Field;
  validationErrors?: ValidationErrors;
  handleChange: (inputName: string, isTranslatableField?: boolean) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const FieldForm: React.FC<Props> = ({ handleChange, inputs, validationErrors }) => {
  return (
    <div className="FieldForm">
      <TextField
        name="en"
        error={!!(validationErrors && validationErrors['name.en'])}
        helperText={validationErrors && validationErrors['name.en'] && <FormattedMessage {...validationErrors['name.en']} />}
        fullWidth={true}
        label={<FormattedMessage id="fields.dataTable.header.en" />}
        value={inputs.name['en']}
        onChange={handleChange('en', true)}
        margin="normal"
      />

      <br /><br />
      <TextField
        name="fr"
        error={!!(validationErrors && validationErrors['name.fr'])}
        helperText={validationErrors && validationErrors['name.fr'] && <FormattedMessage {...validationErrors['name.fr']} />}
        fullWidth={true}
        label={<FormattedMessage id="fields.dataTable.header.fr" />}
        value={inputs.name['fr']}
        onChange={handleChange('fr', true)}
        margin="normal"
      />
    </div>
  );
};

export default FieldForm;
