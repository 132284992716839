import React from 'react';
import { withFirebase } from 'react-redux-firebase';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FirebaseAuth } from '@firebase/auth-types';
import Typography from '@material-ui/core/Typography';

import { PasswordResetForm } from './';
import { Back } from '../common/Buttons';
import { MobileHeader } from '../common/Headers';
import { MobileContent, MobileTitle } from '../common/Layouts';

import { ROOT } from '../../constants/routes';

interface Props {
  firebase: {
    auth: () => FirebaseAuth
  };
  intl: ReactIntl.InjectedIntl;
}

interface State {
  email: string;
  validationError?: {
    code: string;
    message: string;
  };
  isSuccessful: boolean;
}

class PasswordResetContainer extends React.Component<Props, State> {
  readonly state: State = {
    email: '',
    isSuccessful: false
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    this.setState({ email: event.target.value });
  }

  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.setState({ validationError: undefined });
    const { email } = this.state;
    const { firebase } = this.props;

    try {
      await firebase.auth().sendPasswordResetEmail(email);

      this.setState({ email: '', isSuccessful: true });
    }
    catch (e) {
      const validationError = {
        code: e.code,
        message: this.props.intl.formatMessage({id: `firebase.error.${e.code}`})
      };
      this.setState({ validationError });
    }
  }

  render() {
    const { email, isSuccessful, validationError } = this.state;

    return (
      <div className="PasswordResetContainer">
        <MobileHeader withBackground={true} variant="padded" />
        <MobileContent>
          {isSuccessful ? (
            <>
              <MobileTitle>
                <FormattedMessage id="resetPassword.success.title" />
              </MobileTitle>
              <Typography paragraph={true}>
                <FormattedMessage id="resetPassword.success" />
              </Typography>
            </>
          ) : (
            <>
              <MobileTitle>
                <FormattedMessage id="resetPassword.page.title" />
              </MobileTitle>
              <Typography paragraph={true} style={{marginBottom: 0}}>
                <FormattedMessage id="resetPassword.text" />
              </Typography>
              <PasswordResetForm
                email={email}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                validationError={validationError}
              />
            </>
          )}
          <Back to={ROOT}>
            <FormattedMessage id="resetPassword.backToLogin" />
          </Back>
        </MobileContent>
      </div>
    );
  }
}

export default injectIntl(
  withFirebase(PasswordResetContainer)
);
