import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const theme: ThemeOptions = {
  overrides: {
    MuiFab: {
      extended: {
        height: 42,
        padding: '0 30px'
      }
    },
    MuiTableCell: {
      root: {
        '&:last-child': {
          paddingRight: 15
        },
        padding: '4px 15px 4px 10px',
      }
    }
  },
  palette: {
    primary: {
      main: '#0e794d'
    },
    secondary: {
      main: '#41413f'
    }
  },
  typography: {
    useNextVariants: true
  }
};

export default theme;
