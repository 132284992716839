import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { NOTIFICATIONS } from '../../constants/routes';

interface Props {
  notificationKey: string;
  handleArchiveClick?: () => void;
}

interface State {
  anchorElement?: HTMLElement | null;
}

const styles = (theme: Theme) =>
  createStyles({
    kebab: {
      padding: 0
    },
    menuItem: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
      color: theme.palette.primary.main,
      fontSize: '0.9em',
      fontWeight: 500,
    },
    menuItemContent: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      textDecoration: 'none'
    }
  });

class NotificationActions extends React.Component<Props & WithStyles, State> {
  readonly state: State = {
    anchorElement: null
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElement: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorElement: null });
  }

  handleItemClick = () => {
    this.handleClose();
    this.props.handleArchiveClick && this.props.handleArchiveClick();
  }

  render() {
    const { classes, notificationKey } = this.props;
    const { anchorElement } = this.state;
    const open = Boolean(anchorElement);

    return (
      <>
        <IconButton className={classes.kebab} onClick={this.handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorElement}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem className={classes.menuItem} onClick={this.handleClose} component={Link} {...{ to: `${NOTIFICATIONS}/${notificationKey}` } as any}>
            <FormattedMessage id="button.edit.label" />
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={this.handleItemClick}>
            <FormattedMessage id="button.cancel.label" />
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(NotificationActions);
