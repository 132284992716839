import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import moment, { Moment } from 'moment-timezone';

import { AdminNotificationExport, AdminNotificationFilters } from './';
import { DataTable } from '../common/DataTable';
import { MobileTitle } from '../common/Layouts';
import { withAuthorization } from '../Session';
import { withFirebaseLoader } from '../Loader';

import { NotificationFilters, NotificationFilterOptions, SelectorRemoteNotification } from './interfaces';
import { DataTableCol, DataTableOrder } from '../common/DataTable/interfaces';
import { AuthConditionProps, AuthInjectedProps } from '../Session/interfaces';
import { AppState } from '../../redux/reducers/interfaces';

import { filterDataTableEntities } from './filters';
import { getSorting, stableSort } from '../../helpers/dataTable';
import { UserRole } from '../../constants/enums';
import { getUserLocale } from '../../translations';
import { selectors as notificationSelectors } from '../../redux/modules/UserNotifications';

import './AdminNotifications.scss';

const USER_LOCALE = getUserLocale();
const DEFAULT_TIMEZONE = 'Canada/Pacific';

interface Props {
  notifications: SelectorRemoteNotification[];
}

interface State {
  filters: NotificationFilters;
  filterOptions: NotificationFilterOptions;
  globalError?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    commentsCell: {
      maxWidth: 150,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    commentsPopper: {
      whiteSpace: 'pre-line'
    },
    commentsTooltip: {
      fontSize: theme.typography.pxToRem(13)
    }
  });

class AdminNotificationsContainer extends React.Component<Props & AuthInjectedProps & InjectedIntlProps & WithStyles, State> {
  static readonly cols: DataTableCol[] = [
    { id: 'date', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.date" /> },
    { id: 'time', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.time" /> },
    { id: 'field', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.field" /> },
    { id: 'substance', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.substance" /> },
    { id: 'targetCrop', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.targetCrop" /> },
    { id: 'comments', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.comments" /> },
    { id: 'submittedBy', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.submittedBy" /> },
    { id: 'createdAtDate', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.createdAtDate" /> },
    { id: 'createdAtTime', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.createdAtTime" /> },
    { id: 'modifiedAtDate', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.modifiedAtDate" /> },
    { id: 'modifiedAtTime', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.modifiedAtTime" /> },
    { id: 'status', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="adminNotifications.dataTable.header.status" /> }
  ];

  readonly state: State = {
    filterOptions: {
      fields: [],
      substances: [],
      users: []
    },
    filters: {
      date: {
        from: null,
        to: null
      },
      field: [],
      submittedBy: [],
      substance: []
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.notifications !== this.props.notifications) {
      const availableFields = this.props.notifications.map((notification: SelectorRemoteNotification) => {
        return notification.value.field;
      });
      const availableSubstances = this.props.notifications.map((notification: SelectorRemoteNotification) => {
        return notification.value.substance;
      });
      const availableUsers = this.props.notifications.map((notification: SelectorRemoteNotification) => {
        return `${notification.value.lastName}, ${notification.value.firstName}`;
      });

      this.setState({ filterOptions: {
        ...this.state.filterOptions,
        fields: Array.from(new Set(availableFields)),
        substances: Array.from(new Set(availableSubstances)),
        users: Array.from(new Set(availableUsers))
      }});
    }
  }

  handleDateTimeChange = (name: string) => (dateTime: Moment) => {
    this.setState({
      filters: {
        ...this.state.filters,
        date: {
          ...this.state.filters.date,
          [name]: dateTime ? dateTime.toISOString() : null
        }
      }
    });
  }

  handleSelectChange = (name: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: event.target.value
      }
    });
  }

  renderNotificationStatus = (notification: SelectorRemoteNotification) => {
    const { value: { archivedAt, modifiedAt } } = notification;
    let resourceId;

    if (archivedAt) {
      resourceId = 'notifications.status.cancelled';
    }
    else if (modifiedAt) {
      resourceId = 'notifications.status.modified';
    }
    else {
      resourceId = 'notifications.status.new';
    }

    return <FormattedMessage id={resourceId} />
  }

  render() {
    const { classes, intl, notifications } = this.props;
    const { filters, filterOptions } = this.state;

    const parsedNotifications = notifications.map((n: SelectorRemoteNotification) => {
      return {
        ...n,
        value: {
          ...n.value,
          date: moment(n.value.dateTime).startOf('day'),
          submittedBy: `${n.value.lastName}, ${n.value.firstName}`,
          time: moment(n.value.dateTime)
        }
      };
    }).filter(filterDataTableEntities(filters));

    return (
      <div className="AdminNotificationsContainer">
        <MobileTitle withLogout={true}>
          <FormattedMessage id="notifications.page.title" />
        </MobileTitle>
        <div className="AdminNotificationsToolbar">
          <AdminNotificationFilters
            filters={filters}
            filterOptions={filterOptions}
            handleDateTimeChange={this.handleDateTimeChange}
            handleSelectChange={this.handleSelectChange}
          />
          <AdminNotificationExport
            dataset={parsedNotifications}
            timezone={DEFAULT_TIMEZONE}
          />
        </div>
        <DataTable
          archiveCheckboxLabel={<FormattedMessage id="adminNotifications.dataTable.archive.checkbox.label" />}
          cols={AdminNotificationsContainer.cols}
          orderBy="date"
          defaultOrder="desc"
          rowCount={parsedNotifications.length}
        >
          {(order: DataTableOrder, orderBy: string, page: number, rowsPerPage: number) => {
            return (
              stableSort(parsedNotifications, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n: SelectorRemoteNotification, index: number) => {
                const momentDate = moment(n.value.date).format(intl.formatMessage({id: 'app.date.format'}));
                const momentTime = moment.tz(n.value.time, DEFAULT_TIMEZONE);
                const momentCreatedAtDate = moment(n.value.createdAt).format(intl.formatMessage({id: 'app.date.format'}));
                const momentCreatedAtTime = moment.tz(n.value.createdAt, DEFAULT_TIMEZONE).format(intl.formatMessage({id: 'app.time.format'}));
                const momentModifiedAtDate = moment(n.value.modifiedAt).format(intl.formatMessage({id: 'app.date.format'}));
                const momentModifiedAtTime = moment.tz(n.value.modifiedAt, DEFAULT_TIMEZONE).format(intl.formatMessage({id: 'app.time.format'}));
                const momentArchivedAtDate = moment(n.value.archivedAt).format(intl.formatMessage({id: 'app.date.format'}));
                const momentArchivedAtTime = moment.tz(n.value.archivedAt, DEFAULT_TIMEZONE).format(intl.formatMessage({id: 'app.time.format'}));

                return (
                  <TableRow
                    className={n.value.isArchived ? 'TableRow-archived' : ''}
                    classes={{
                      hover: 'TableRow-hover'
                    }}
                    hover={true}
                    tabIndex={-1}
                    key={index}
                  >
                    <TableCell>
                      {momentDate[0].toUpperCase() + momentDate.substr(1)}
                    </TableCell>
                    <TableCell>
                      {momentTime.format(intl.formatMessage({id: 'app.time.format'}))} ({momentTime.zoneAbbr()})
                    </TableCell>
                    <TableCell>{n.value.field}</TableCell>
                    <TableCell>{n.value.substance}</TableCell>
                    <TableCell>{n.value.targetCrop}</TableCell>
                    <Tooltip
                      classes={{
                        popper: classes.commentsPopper,
                        tooltip: classes.commentsTooltip,
                      }}
                      title={n.value.comments}
                      placement="bottom-start"
                    >
                      <TableCell className={classes.commentsCell}>{n.value.comments}</TableCell>
                    </Tooltip>
                    <TableCell>
                      {n.value.submittedBy}
                      <span className="ExtraInfo">{n.value.email}</span>
                      <span className="ExtraInfo">{n.value.farm}</span>
                    </TableCell>
                    <TableCell>
                      {n.value.createdAt ? (
                        `${momentCreatedAtDate[0].toUpperCase() + momentCreatedAtDate.substr(1)}`
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {n.value.createdAt ? (
                        `${momentCreatedAtTime} (${momentTime.zoneAbbr()})`
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {n.value.modifiedAt ? (
                        `${momentModifiedAtDate[0].toUpperCase() + momentModifiedAtDate.substr(1)}`
                      ) : (
                        n.value.archivedAt ? (
                          `${momentArchivedAtDate[0].toUpperCase() + momentArchivedAtDate.substr(1)}`
                        ) : (
                          '-'
                        )
                      )}
                    </TableCell>
                    <TableCell>
                      {n.value.modifiedAt ? (
                        `${momentModifiedAtTime} (${momentTime.zoneAbbr()})`
                      ) : (
                        n.value.archivedAt ? (
                          `${momentArchivedAtTime} (${momentTime.zoneAbbr()})`
                        ) : (
                          '-'
                        )
                      )}
                    </TableCell>
                    <TableCell>
                      {this.renderNotificationStatus(n)}
                    </TableCell>
                  </TableRow>
                );
              })
            );
          }}
        </DataTable>
      </div>
    );
  }
}

const firebaseQuery = () => {
  return [
    { path: 'farms' },
    { path: 'fields' },
    { path: 'substances' },
    { path: 'users' },
    { path: 'notifications', storeAs: 'adminNotifications' },
  ];
};

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps) => {
  const params = new URLSearchParams(ownProps.location.search);
  const includeArchived = params.get('includeArchived') || false;

  return {
    notifications: notificationSelectors.selectNotifications(state, USER_LOCALE, includeArchived) || [],
  };
};

const authCondition = (authUser: AuthConditionProps) => Object.keys(authUser.profile.roles).includes(UserRole.ADMIN);
const loaderEntities = ['fields, notifications, substances, users'];

export default compose(
  withStyles(styles),
  injectIntl,
  withAuthorization(authCondition),
  connect(mapStateToProps),
  firebaseConnect(firebaseQuery),
  withFirebaseLoader(loaderEntities)
)(AdminNotificationsContainer);
