import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { SortableTableHead } from './';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { FormDialog } from '../FormDialog';

import { DataTableActionState, DataTableCol, DataTableOrder } from './interfaces';

import { rowsPerPageOptions } from '../../../constants/dataTables';
import * as routes from '../../../constants/routes';
import { DataTableAction } from '../../../constants/enums';

import './DataTable.scss';

type OpenDialog = (action: string, itemKey?: string) => (event: React.MouseEvent<HTMLButtonElement>) => void;

interface Props extends InjectedIntlProps {
  addButtonLabel?: React.ReactNode;
  addEditForm?: (action: string) => React.ReactNode;
  addEditDialogCancelLabel?: React.ReactNode;
  addEditDialogConfirmLabel?: React.ReactNode;
  addDialogTitle?: React.ReactNode;
  archiveCheckboxLabel?: React.ReactNode;
  archiveDialogCancelLabel?: React.ReactNode;
  archiveDialogConfirmLabel?: React.ReactNode;
  archiveDialogMessage?: React.ReactNode;
  archiveDialogTitle?: React.ReactNode;
  unarchiveDialogCancelLabel?: React.ReactNode;
  unarchiveDialogConfirmLabel?: React.ReactNode;
  unarchiveDialogMessage?: React.ReactNode;
  unarchiveDialogTitle?: React.ReactNode;
  editDialogTitle?: React.ReactNode;
  children: (order: DataTableOrder, orderBy: string, page: number, rowsPerPage: number, openDialog: OpenDialog) => React.ReactNode;
  cols: DataTableCol[];
  defaultOrder?: DataTableOrder;
  handleDialogClose?: () => void;
  handleDialogConfirm?: (dataTableAction: DataTableActionState) => void;
  handleDialogOpen?: (action: string, itemKey?: string) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  orderBy: string;
  rowCount: number;
}

interface State {
  dataTableAction: DataTableActionState;
  includeArchived: boolean;
  order: DataTableOrder;
  orderBy: string;
  page: number;
  rowsPerPage: number;
}

const styles = (theme: Theme) =>
  createStyles({
    showArchived: {
      marginRight: theme.spacing.unit + 17
    }
  });

class DataTable extends React.Component<Props & RouteComponentProps & WithStyles, State> {
  static defaultProps = {
    addEditDialogCancelLabel: <FormattedMessage id="button.cancel.label" />,
    addEditDialogConfirmLabel: <FormattedMessage id="button.submit.label" />,
    archiveDialogCancelLabel: <FormattedMessage id="button.cancel.label" />,
    archiveDialogConfirmLabel: <FormattedMessage id="button.confirm.label" />,
    unarchiveDialogCancelLabel: <FormattedMessage id="button.cancel.label" />,
    unarchiveDialogConfirmLabel: <FormattedMessage id="button.confirm.label" />
  };

  readonly state: State = {
    dataTableAction: {},
    includeArchived: false,
    order: this.props.defaultOrder || 'asc',
    orderBy: this.props.orderBy,
    page: 0,
    rowsPerPage: rowsPerPageOptions[0]
  };

  componentDidMount() {
    if (this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      const includeArchived = !!params.get('includeArchived') || false;
      this.setState({ includeArchived });
    }
  }

  closeDialog = () => {
    this.setState({
      dataTableAction: {}
    }, () => this.props.handleDialogClose && this.props.handleDialogClose());
  }

  openDialog = (action: string, itemKey?: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      dataTableAction: {
        [action]: itemKey
      }
    }, () => this.props.handleDialogOpen && this.props.handleDialogOpen(action, itemKey)(event));
  }

  confirmDialog = async () => {
    try {
      if (this.props.handleDialogConfirm) {
        await this.props.handleDialogConfirm(this.state.dataTableAction);
      }
      this.closeDialog();
    }
    catch (e) {
      console.error(e);
      // ignore error
    }
  }

  handleIncludeArchivedChange = () => (_0: React.ChangeEvent<HTMLInputElement>) => {
    const pushTo = !this.state.includeArchived ? routes.INCLUDE_ARCHIVED : '?';
    this.setState({ includeArchived: !this.state.includeArchived }, () => {
      this.props.history.push(pushTo);
    });
  }

  handleRequestSort = (_0: React.SyntheticEvent, property: string) => {
    const orderBy = property;
    let order: DataTableOrder = this.state.order;

    if (this.state.orderBy === property) {
      if (this.state.order === 'desc') {
        order = 'asc';
      }
      else {
        order = 'desc';
      }
    }

    this.setState({ order, orderBy });
  }

  handleChangePage = (_0: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ rowsPerPage: Number(event.target.value) });
  }

  render() {
    const {
      addButtonLabel, addEditDialogCancelLabel, addEditDialogConfirmLabel, addEditForm, archiveCheckboxLabel, archiveDialogCancelLabel,
      archiveDialogConfirmLabel, archiveDialogMessage, archiveDialogTitle, addDialogTitle, classes, editDialogTitle,
      children, cols, rowCount, unarchiveDialogCancelLabel, unarchiveDialogConfirmLabel, unarchiveDialogMessage, unarchiveDialogTitle
    } = this.props;

    const { dataTableAction, includeArchived, order, orderBy, rowsPerPage, page } = this.state;
    const currentDataTableAction = Object.keys(dataTableAction)[0];
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowCount - page * rowsPerPage);
    let formDialogTitle: React.ReactNode | string = '';
    let confirmDialogTitle: React.ReactNode | string = '';
    let confirmDialogMessage: React.ReactNode | string = '';
    let confirmDialogCancel: React.ReactNode | string = '';
    let confirmDialogConfirm: React.ReactNode | string = '';
    switch (currentDataTableAction) {
      case DataTableAction.ADD: {
        formDialogTitle = addDialogTitle;
        break;
      }
      case DataTableAction.ARCHIVE: {
        confirmDialogTitle = archiveDialogTitle;
        confirmDialogMessage = archiveDialogMessage;
        confirmDialogCancel = archiveDialogCancelLabel;
        confirmDialogConfirm = archiveDialogConfirmLabel;
        break;
      }
      case DataTableAction.EDIT: {
        formDialogTitle = editDialogTitle;
        break;
      }
      case DataTableAction.UNARCHIVE: {
        confirmDialogTitle = unarchiveDialogTitle;
        confirmDialogMessage = unarchiveDialogMessage;
        confirmDialogCancel = unarchiveDialogCancelLabel;
        confirmDialogConfirm = unarchiveDialogConfirmLabel;
        break;
      }
    }

    return (
      <>
        <div className="DataTable">
          <div className="DataTableHeader">
            <FormControlLabel
              className={classes.showArchived}
              control={
                <Checkbox
                  checked={includeArchived}
                  onChange={this.handleIncludeArchivedChange()}
                  value="includeArchived"
                  color="primary"
                />
              }
              label={archiveCheckboxLabel ? (
                archiveCheckboxLabel
              ) : (
                <FormattedMessage id="dataTable.actions.includeArchived.label" />
              )}
            />
            {addEditForm &&
              <Button type="button" variant="contained" color="primary" onClick={this.openDialog(DataTableAction.ADD)}>
                {addButtonLabel}
              </Button>
            }
          </div>
          <Table>
            <SortableTableHead
              cols={cols}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {children(order, orderBy, page, rowsPerPage, this.openDialog)}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={cols.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rowCount}
          labelRowsPerPage={<FormattedMessage id="dataTable.pagination.rowsPerPage.label" />}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${this.props.intl.formatMessage({id: 'dataTable.pagination.displayedRows.label'})} ${count}`}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />

        <FormDialog
          open={[DataTableAction.EDIT as string, DataTableAction.ADD as string].includes(currentDataTableAction)}
          dialogCancelLabel={addEditDialogCancelLabel}
          dialogConfirmLabel={addEditDialogConfirmLabel}
          dialogTitle={formDialogTitle}
          handleClose={this.closeDialog}
          handleConfirm={this.confirmDialog}
        >
          {addEditForm && addEditForm(currentDataTableAction)}
        </FormDialog>

        <ConfirmationDialog
          open={[DataTableAction.ARCHIVE as string, DataTableAction.UNARCHIVE as string].includes(currentDataTableAction)}
          dialogCancelLabel={confirmDialogCancel}
          dialogConfirmLabel={confirmDialogConfirm}
          dialogMessage={confirmDialogMessage}
          dialogTitle={confirmDialogTitle}
          handleClose={this.closeDialog}
          handleConfirm={this.confirmDialog}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(injectIntl(DataTable)));
