import React from 'react';
import { withFirebase } from 'react-redux-firebase';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import { LoginForm } from './';
import { MobileHeader } from '../common/Headers';

import './Login.scss';

interface Props extends InjectedIntlProps{
  firebase: {
    login: (options: object) => Promise<object>
  };
}

interface State {
  inputs: {
    email: string;
    password: string;
  };
  showPassword: boolean;
  validationError?: {
    code: string;
    message: string;
  };
}

class LoginContainer extends React.Component<Props, State> {
  readonly state: State = {
    inputs: {
      email: '',
      password: ''
    },
    showPassword: false
  };

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [name]: event.target.value
      }
    });
  }

  handleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.setState({ validationError: undefined });
    const { inputs: {email, password } } = this.state;
    try {
      await this.props.firebase.login({
        email,
        password
      });
    }
    catch (e) {
      const validationError = {
        code: e.code,
        message: this.props.intl.formatMessage({id: `firebase.error.${e.code}`})
      };
      this.setState({ validationError });
    }
  }

  render() {
    const { inputs, showPassword, validationError } = this.state;
    return (
      <div className="LoginContainer">
        <div className="LoginContainer-content">
          <MobileHeader />
          <LoginForm
            inputs={inputs}
            handleChange={this.handleChange}
            handleShowPassword={this.handleShowPassword}
            handleSubmit={this.handleSubmit}
            showPassword={showPassword}
            validationError={validationError}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(
  withFirebase(LoginContainer)
);
