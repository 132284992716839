import validator from 'validator';

import { Condition, Inputs, Rules, ValidationErrors } from './interfaces';

export const validate = (inputs: Inputs, rules: Rules) => {
  const v = validator as any;
  const validationErrors: ValidationErrors = {};

  Object.keys(rules).forEach(input => {
    const splitValue = input.split('.').reduce((acc: any, prop: string) => acc[prop], inputs);
    const inputValue = typeof splitValue === 'undefined' ? inputs[input] : splitValue;
    const runConditional = performConditionalValidation(rules[input].condition, inputs);

    if (runConditional) {
      for (const validatorFn of Object.keys(rules[input].validators)) {
        if (!v[validatorFn]) {
          console.error(`Supplied validator does not exist: ${validatorFn}`);
          break;
        }
        let isValid;
        switch (validatorFn) {
          case 'isEmpty': {
            isValid = !v[validatorFn](inputValue.toString());
            break;
          }
          case 'isLength': {
            const lengthOptions = rules[input].validators[validatorFn].options;
            isValid = inputValue.toString() === '' || v[validatorFn](inputValue.toString(), lengthOptions);
            break;
          }
          case 'matches': {
            const fieldToCompare = rules[input].validators[validatorFn].field || '';
            isValid = v[validatorFn](inputValue.toString(), inputs[fieldToCompare]);
            break;
          }
          default: {
            isValid = inputValue.toString() === '' || v[validatorFn](inputValue.toString());
            break;
          }
        }

        if (!isValid) {
          validationErrors[input] = rules[input].validators[validatorFn].message;
          break;
        }
      }
    }
  });

  return validationErrors;
};

const performConditionalValidation = (condition: Condition | undefined, inputs: Inputs) : boolean => {
  if (condition) {
    const valueToCompare = inputs[condition.field];
    return validator.matches(valueToCompare, condition.value);
  }

  return true;
};
