import { FirebaseConfig } from '../interfaces';

const auth: FirebaseConfig = {
  apiKey: 'AIzaSyBSGb9n83OKAdnsK6VpUpZA-0qxMA-BPuo',
  authDomain: 'alaksen-farm-notification-app.firebaseapp.com',
  databaseURL: 'https://alaksen-farm-notification-app.firebaseio.com',
  messagingSenderId: '986987716068',
  projectId: 'alaksen-farm-notification-app',
  storageBucket: 'alaksen-farm-notification-app.appspot.com'
};

export const cloudFunctionsUrl = `https://us-central1-${auth.projectId}.cloudfunctions.net`;

export default auth;
