import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { DatePicker } from 'material-ui-pickers';
import { Moment } from 'moment';

import { UserNotification } from './interfaces';
import { OrderedRemoteField } from '../Fields/interfaces';
import { OrderedRemoteSubstance } from '../Substances/interfaces';
import { getUserLocale } from '../../translations';
import { ValidationErrors } from '../../helpers/interfaces';

import { getTimeArray } from '../../helpers/dateTime';

interface Props extends InjectedIntlProps {
  inputOptions: {
    fields: OrderedRemoteField[];
    substances: OrderedRemoteSubstance[];
  };
  inputs: UserNotification;
  isNew?: boolean;
  validationErrors?: ValidationErrors;
  handleChange: (inputName: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleDateTimeChange: (inputName: string) => (dateTime: Moment) => void;
  handleSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    back: {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      marginLeft: -10
    },
    form: {
      marginBottom: 40,
      padding: 30
    }
  });

const NotificationForm: React.FC<Props & WithStyles> = ({ classes, handleChange, handleDateTimeChange, handleSubmit, inputOptions, intl, inputs, isNew = false, validationErrors }) => {
  const userLocale = getUserLocale();

  return (
    <div className="NewNotificationForm">
      <Paper className={classes.form}>
        <form onSubmit={handleSubmit}>
          <DatePicker
            label={<FormattedMessage id="notifications.date.label" />}
            disablePast={true}
            error={!!(validationErrors && validationErrors['activityDate'])}
            helperText={validationErrors && validationErrors['activityDate'] && <FormattedMessage {...validationErrors['activityDate']} />}
            fullWidth={true}
            format={intl.formatMessage({id: 'form.field.dateTimePicker.fieldFormat'})}
            value={inputs.activityDate}
            onChange={handleDateTimeChange('activityDate')}
            cancelLabel={<FormattedMessage id="button.cancel.label" />}
            okLabel={<FormattedMessage id="button.ok.label" />}
          />

          <TextField
            name="activityTime"
            error={!!(validationErrors && validationErrors['activityTime'])}
            helperText={validationErrors && validationErrors['activityTime'] && <FormattedMessage {...validationErrors['activityTime']} />}
            select={true}
            fullWidth={true}
            label={<FormattedMessage id="notifications.time.label" />}
            value={inputs.activityTime}
            onChange={handleChange('activityTime')}
            SelectProps={{
              native: true
            }}
            margin="normal"
          >
            <option value="" disabled={true} />
            {getTimeArray().map((option: string) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>

          <br />
          <TextField
            name="treatmentComments"
            error={!!(validationErrors && validationErrors['treatmentComments'])}
            helperText={validationErrors && validationErrors['treatmentComments'] && <FormattedMessage {...validationErrors['treatmentComments']} />}
            fullWidth={true}
            label={<FormattedMessage id="notifications.treatmentComments.label" />}
            multiline={true}
            rowsMax="4"
            value={inputs.treatmentComments}
            onChange={handleChange('treatmentComments')}
            margin="normal"
          />

          <TextField
            name="field"
            error={!!(validationErrors && validationErrors['field'])}
            helperText={validationErrors && validationErrors['field'] && <FormattedMessage {...validationErrors['field']} />}
            select={true}
            fullWidth={true}
            label={<FormattedMessage id="notifications.field.label" />}
            value={inputs.field}
            onChange={handleChange('field')}
            SelectProps={{
              native: true
            }}
            margin="normal"
          >
            <option value="" disabled={true} />
            {inputOptions.fields.map((option: OrderedRemoteField) => (
              <option key={option.key} value={option.key} disabled={option.value.isArchived}>
                {option.value.name[userLocale]}
              </option>
            ))}
          </TextField>

          <br />
          <TextField
            name="substance"
            error={!!(validationErrors && validationErrors['substance'])}
            helperText={validationErrors && validationErrors['substance'] && <FormattedMessage {...validationErrors['substance']} />}
            select={true}
            fullWidth={true}
            label={<FormattedMessage id="notifications.substance.label" />}
            value={inputs.substance}
            onChange={handleChange('substance')}
            SelectProps={{
              native: true
            }}
            margin="normal"
          >
            <option value="" disabled={true} />
            {inputOptions.substances.map((option: OrderedRemoteSubstance) => (
              <option key={option.key} value={option.key} disabled={option.value.isArchived}>
                {(option.value.id) ? `${option.value.id} - ` : ''} {option.value.name[userLocale]}
              </option>
            ))}
          </TextField>

          <br />
          <TextField
            name="comments"
            error={!!(validationErrors && validationErrors['comments'])}
            helperText={validationErrors && validationErrors['comments'] && <FormattedMessage {...validationErrors['comments']} />}
            fullWidth={true}
            label={<FormattedMessage id="notifications.comments.label" />}
            multiline={true}
            rowsMax="4"
            value={inputs.comments}
            onChange={handleChange('comments')}
            margin="normal"
          />

          <br />
          <TextField
            name="targetCrop"
            error={!!(validationErrors && validationErrors['targetCrop'])}
            helperText={validationErrors && validationErrors['targetCrop'] && <FormattedMessage {...validationErrors['targetCrop']} />}
            fullWidth={true}
            label={<FormattedMessage id="notifications.targetCrop.label" />}
            value={inputs.targetCrop}
            onChange={handleChange('targetCrop')}
            margin="normal"
            inputProps={{maxLength: 30}}
          />

          <br /><br /><br />
          <Fab type="submit" variant="extended" color="primary">
            {isNew ? (
              <FormattedMessage id="button.submit.label" />
            ) : (
              <FormattedMessage id="button.edit.label" />
            )}
          </Fab>
        </form>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(injectIntl(NotificationForm));
