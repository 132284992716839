import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';

import { AppState } from './interfaces';

const rootReducer = combineReducers<AppState>({
  firebase: firebaseReducer
});

export default rootReducer;
