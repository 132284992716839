import moment from 'moment';

export const filterDataTableEntities = (filters: any) => (entity: any) => {
  const entityDate = moment(entity.value.dateTime);
  let matches = true;

  for (const filter of Object.keys(filters)) {
    if (filters[filter] !== null) {
      if (Array.isArray(filters[filter]) && filters[filter].length > 0) {
        matches = filters[filter].includes(entity.value[filter]);
        if (!matches) return;
      }

      if (filter === 'date') {
        const fromFilterDate = filters[filter].from ? moment(filters[filter].from) : null;
        const toFilterDate = filters[filter].to ? moment(filters[filter].to) : null;

        if (fromFilterDate && toFilterDate) {
          matches = entityDate.isSameOrAfter(fromFilterDate) && entityDate.isSameOrBefore(toFilterDate);
        }
        else if (fromFilterDate) {
          matches = entityDate.isSameOrAfter(fromFilterDate);
        }
        else if (toFilterDate) {
          matches = entityDate.isSameOrBefore(toFilterDate);
        }

        if (!matches) return;
      }
    }
  }

  return matches;
};
