import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/icons/KeyboardArrowDown';

interface Props {
  handleLoadMore: () => void;
}

const LoadMore: React.FC<Props> = ({ handleLoadMore }) => {
  return (
    <div className="LoadMore">
      <Button size="small" onClick={handleLoadMore}>
        <FormattedMessage id="button.loadMore.label" />
        <Icon />
      </Button>
    </div>
  );
};

export default LoadMore;
