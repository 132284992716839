import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AdminNavigation } from './';
import { MobileHeader } from '../common/Headers';
import { AdminNotificationsContainer } from '../AdminNotifications';
import { FieldsContainer } from '../Fields';
import { SubstancesContainer } from '../Substances';
import { UsersContainer } from '../Users';
import { EmailRecipientsContainer } from '../EmailRecipients';

import * as routes from '../../constants/routes';

import './Administration.scss';

const Administration: React.FC = () => {
  return (
    <div className="Administration">
      <MobileHeader withBackground={true} />
      <div className="AdministrationContent">
        <AdminNavigation />
        <div className="AdministrationInnerContent">
          <Switch>
            <Route exact={true} path={routes.ROOT} component={AdminNotificationsContainer} />
            <Route exact={true} path={routes.FIELDS} component={FieldsContainer} />
            <Route exact={true} path={routes.SUBSTANCES} component={SubstancesContainer} />
            <Route exact={true} path={routes.USERS} component={UsersContainer} />
            <Route exact={true} path={routes.EMAIL_RECIPIENTS} component={EmailRecipientsContainer} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Administration;
