import React from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';

import { ImportantMessage } from './interfaces';
import { ValidationErrors } from '../../helpers/interfaces';

interface Props {
  inputs: ImportantMessage;
  validationErrors?: ValidationErrors;
  handleChange: (inputName: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const ConfigureImportantMessageForm: React.FC<Props> = ({ handleChange, inputs, validationErrors }) => {
  return (
    <div className="ConfigureImportantMessageForm">
      <TextField
        name="en"
        error={!!(validationErrors && validationErrors['en'])}
        helperText={validationErrors && validationErrors['en'] && <FormattedMessage {...validationErrors['en']} />}
        fullWidth={true}
        label={<FormattedMessage id="importantMessage.en.label" />}
        value={inputs['en']}
        multiline={true}
        rowsMax="4"
        onChange={handleChange('en')}
        margin="normal"
      />

      <br /><br />
      <TextField
        name="fr"
        error={!!(validationErrors && validationErrors['fr'])}
        helperText={validationErrors && validationErrors['fr'] && <FormattedMessage {...validationErrors['fr']} />}
        fullWidth={true}
        label={<FormattedMessage id="importantMessage.fr.label" />}
        value={inputs['fr']}
        multiline={true}
        rowsMax="4"
        onChange={handleChange('fr')}
        margin="normal"
      />
    </div>
  );
};

export default ConfigureImportantMessageForm;
