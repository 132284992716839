import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import 'whatwg-fetch';

import { UserForm, UserRoleList } from './';
import { ActionsTableCell, DataTable, DataTableActions } from '../common/DataTable';
import { MobileTitle } from '../common/Layouts';
import { ErrorSnackbar } from '../common/Snackbars';
import { withFirebaseLoader } from '../Loader';
import { withAuthorization } from '../Session';

import { DataTableActionState, DataTableCol, DataTableOrder } from '../common/DataTable/interfaces';
import { OrderedRemoteFarm } from '../Farms/interfaces';
import { OrderedRemoteUser, User } from './interfaces';
import { AuthConditionProps } from '../Session/interfaces';
import { AppState } from '../../redux/reducers/interfaces';
import { ValidationErrors } from '../../helpers/interfaces';

import { getSorting, stableSort } from '../../helpers/dataTable';
import { getUserLocale } from '../../translations';
import { validate } from '../../helpers/validation';
import editUserValidationRules from './editUserRules';
import newUserValidationRules from './newUserRules';
import { DataTableAction, UserRole } from '../../constants/enums';

const USER_LOCALE = getUserLocale();

interface Props {
  authUser: {
    uid: string;
  };
  farms: OrderedRemoteFarm[];
  firebase: {
    push: (path: string, data: object) => Promise<object>
    update: (path: string, data: object) => Promise<object>
  };
  users: OrderedRemoteUser[];
}

interface State {
  globalError?: string;
  inputs: User;
  showGlobalError: boolean;
  validationErrors?: ValidationErrors;
}

class UsersContainer extends React.Component<Props & InjectedIntlProps, State> {
  static readonly inputsInitialState: User = {
    email: '',
    farm: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    role: '',
    roles: {}
  };

  static readonly cols: DataTableCol[] = [
    { id: 'email', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="users.dataTable.header.email" /> },
    { id: 'firstName', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="users.dataTable.header.firstName" /> },
    { id: 'lastName', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="users.dataTable.header.lastName" /> },
    { id: 'role', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="users.dataTable.header.role" /> },
    { id: 'farm', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="users.dataTable.header.farm" /> },
    { id: 'actions', sortable: false, numeric: false, disablePadding: false, label: <FormattedMessage id="dataTable.header.actions" /> },
  ];

  readonly state: State = {
    inputs: UsersContainer.inputsInitialState,
    showGlobalError: false
  };

  closeDialog = () => {
    this.setState({
      inputs: UsersContainer.inputsInitialState,
      validationErrors: undefined
    });
  }

  closeSnackbar = () => {
    this.setState({
      showGlobalError: false
    });
  }

  confirmDialog = async (dataTableAction: DataTableActionState) => {
    this.setState({ globalError: undefined, validationErrors: undefined });
    const { inputs } = this.state;
    const action = Object.keys(dataTableAction)[0];
    const validationRules = action === DataTableAction.ADD ? newUserValidationRules : editUserValidationRules;
    const validationErrors = validate(inputs, validationRules);

    if ((action !== DataTableAction.ARCHIVE && action !== DataTableAction.UNARCHIVE) && Object.keys(validationErrors).length) {
      this.setState({ validationErrors });
      throw Error('validation errors');
    }
    else {
      const { uid } = this.props.authUser;
      const cloudFunctionsUrl = require(`../../firebase/configs/${process.env.REACT_APP_FIREBASE_ENV}`).cloudFunctionsUrl;
      const body = {
        ...inputs,
        key: Object.values(dataTableAction)[0] || undefined,
        roles: {
          [inputs.role]: 1
        }
      };

      let endpoint;
      switch (action) {
        case DataTableAction.ADD: {
          endpoint = 'createUser';
          break;
        }
        case DataTableAction.EDIT: {
          endpoint = 'updateUser';
          break;
        }
        case DataTableAction.ARCHIVE: {
          endpoint = 'archiveUser';
          break;
        }
        case DataTableAction.UNARCHIVE: {
          endpoint = 'unarchiveUser';
          break;
        }
      }

      try {
        const response = await fetch(`${cloudFunctionsUrl}/${endpoint}`, {
          body: JSON.stringify(body),
          headers: {
            'authentication': uid,
          },
          method: 'POST'
        });

        if (response.status !== 201) throw response.status;
      }
      catch (e) {
        this.setState({ globalError: 'firebase.error.firebase/unknown', showGlobalError: true });
        throw e;
      }
    }
  }

  openDialog = (action: string, entityKey?: string) => (_0: React.MouseEvent<HTMLButtonElement>) => {
    const { users } = this.props;

    if (action === DataTableAction.EDIT) {
      const user = users.find((s: OrderedRemoteUser) => s.key === entityKey);
      if (user) {
        const populatedInputs = {
          ...user.value,
          farm: user.value.farm || '',
          password: '',
          passwordConfirmation: '',
          role: Object.keys(user.value.roles)[0]
        };
        this.setState({ inputs: populatedInputs });
      }
    }
  }

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [name]: event.target.value
      }
    });
  }

  render() {
    const { authUser, farms, intl, users } = this.props;
    const { globalError, inputs, showGlobalError, validationErrors } = this.state;

    const filteredUsers = users.filter((user: OrderedRemoteUser) => user.key !== authUser.uid && !Object.keys(user.value.roles).includes(UserRole.SUPER_ADMIN)).map((user: OrderedRemoteUser) => {
      const selectedFarm: OrderedRemoteFarm | undefined = farms.find((farm: OrderedRemoteFarm) => farm.key === user.value.farm);
      const userRole = Object.keys(user.value.roles).map((role: string, index: number) => {
        return intl.formatMessage({id: `roles.${role}.label`})
      });
      return {
        ...user,
        value: {
          ...user.value,
          role: userRole,
          farm: selectedFarm && selectedFarm.value.name[USER_LOCALE] || '-'
        }
      }
    });

    const filteredFarms = farms.filter((farm: OrderedRemoteFarm) => !farm.value.isArchived || inputs.farm === farm.key).sort((a: OrderedRemoteFarm, b: OrderedRemoteFarm) => {
      if (a.value.name[USER_LOCALE] < b.value.name[USER_LOCALE]) return -1;
      if (a.value.name[USER_LOCALE] > b.value.name[USER_LOCALE]) return 1;
      return 0;
    });

    const userForm = (action: string) => {
      const isNew = action === DataTableAction.ADD;
      return (
        <UserForm
          inputOptions={{farms: filteredFarms}}
          inputs={inputs}
          isNew={isNew}
          validationErrors={validationErrors}
          handleChange={this.handleChange}
        />
      );
    };

    return (
      <div className="UsersContainer">
        <MobileTitle withLogout={true}>
          <FormattedMessage id="users.page.title" />
        </MobileTitle>
        <DataTable
          addButtonLabel={<FormattedMessage id="users.button.add" />}
          addEditForm={userForm}
          addDialogTitle={<FormattedMessage id={'users.adding.dialog.title'} />}
          archiveDialogCancelLabel={<FormattedMessage id="users.archive.dialog.button.cancel" />}
          archiveDialogConfirmLabel={<FormattedMessage id="users.archive.dialog.button.confirm" />}
          archiveDialogMessage={<FormattedMessage id="users.archive.dialog.message" />}
          archiveDialogTitle={<FormattedMessage id="users.archive.dialog.title" />}
          unarchiveDialogCancelLabel={<FormattedMessage id="users.unarchive.dialog.button.cancel" />}
          unarchiveDialogConfirmLabel={<FormattedMessage id="users.unarchive.dialog.button.confirm" />}
          unarchiveDialogMessage={<FormattedMessage id="users.unarchive.dialog.message" />}
          unarchiveDialogTitle={<FormattedMessage id="users.unarchive.dialog.title" />}
          editDialogTitle={<FormattedMessage id="users.editing.dialog.title" />}
          cols={UsersContainer.cols}
          orderBy="email"
          rowCount={filteredUsers.length}
          handleDialogClose={this.closeDialog}
          handleDialogConfirm={this.confirmDialog}
          handleDialogOpen={this.openDialog}
        >
          {(order: DataTableOrder, orderBy: string, page: number, rowsPerPage: number, openDialog: any) => {
            return (
              stableSort(filteredUsers, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n: OrderedRemoteUser) => {
                return (
                  <TableRow
                    className={n.value.isArchived ? 'TableRow-archived' : ''}
                    classes={{hover: 'TableRow-hover'}}
                    hover={true}
                    tabIndex={-1}
                    key={n.key}
                  >
                    <TableCell>{n.value.email}</TableCell>
                    <TableCell>{n.value.firstName}</TableCell>
                    <TableCell>{n.value.lastName}</TableCell>
                    <TableCell><UserRoleList roles={n.value.roles} /></TableCell>
                    <TableCell>{n.value.farm}</TableCell>
                    <ActionsTableCell>
                      <DataTableActions
                        entityKey={n.key}
                        handleDialog={openDialog}
                        isArchived={n.value.isArchived}
                      />
                    </ActionsTableCell>
                  </TableRow>
                );
              })
            );
          }}
        </DataTable>
        <ErrorSnackbar
          handleClose={this.closeSnackbar}
          message={globalError}
          open={showGlobalError}
        />
      </div>
    );
  }
}

const firebaseQuery = (props: RouteComponentProps) => {
  const params = new URLSearchParams(props.location.search);
  const includeArchived = params.get('includeArchived') || false;
  const queryParams = includeArchived ? [] : ['parsed', 'orderByChild=isArchived', 'equalTo=false'];

  return [
    { path: 'farms'},
    { path: 'users', queryParams},
  ];
};

const mapStateToProps = (state: AppState) => {
  return {
    authUser: state.firebase.auth,
    farms: state.firebase.ordered.farms,
    users: state.firebase.ordered.users || []
  };
};

const authCondition = (authUser: AuthConditionProps) => Object.keys(authUser.profile.roles).includes(UserRole.ADMIN);
const loaderEntities = ['farms', 'users'];

export default compose(
  injectIntl,
  withAuthorization(authCondition),
  connect(mapStateToProps),
  firebaseConnect(firebaseQuery),
  withFirebaseLoader(loaderEntities),
)(UsersContainer);
