export default {
  'email': {
    validators: {
      isEmail: {
        message: {
          id: 'validation.isEmail'
        }
      },
      isEmpty: {
        message: {
          id: 'validation.isEmpty'
        }
      }
    }
  }
};
  