import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  children: React.ReactNode;
  dialogCancelLabel: React.ReactNode;
  dialogConfirmLabel: React.ReactNode;
  dialogTitle: React.ReactNode;
  handleClose: () => void;
  handleConfirm: (...args: any) => void;
  open: boolean;
}

const FormDialog: React.FC<Props> = ({ children, dialogCancelLabel, dialogConfirmLabel, dialogTitle, handleClose, handleConfirm, open }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {dialogCancelLabel}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus={true}>
          {dialogConfirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
