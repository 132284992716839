import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';

import './PasswordReset.scss';

interface Props extends InjectedIntlProps {
  email: string;
  validationError?: {
    code: string;
    message: string;
  };
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  handleSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;
}

const PasswordResetForm: React.FC<Props> = ({ email, handleChange, handleSubmit, intl, validationError }) => {
  return (
    <div className="PasswordResetForm">
      <form onSubmit={handleSubmit}>
        <TextField
          name="email"
          error={!!validationError}
          helperText={validationError && <FormattedMessage id={validationError.message} />}
          fullWidth={true}
          label={<FormattedMessage id="form.field.email.label" />}
          value={email}
          onChange={handleChange}
          margin="normal"
        />

        <br /><br />
        <Fab type="submit" variant="extended" color="primary">
          <FormattedMessage id="resetPassword.submit" />
        </Fab>
      </form>
    </div>
  );
};

export default injectIntl(PasswordResetForm);
