import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/fr';
import { IntlProvider, addLocaleData } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_fr from 'react-intl/locale-data/fr';

import { App } from './components/App';

import LocalizedUtils from './helpers/LocalizedUtils';
import * as serviceWorker from './serviceWorker';
import firebaseApp from './firebase';
import store from './redux/store';
import appMessages, { getUserLocale } from './translations';
import theme from './theme';

import './index.scss';

const userLocale = getUserLocale();
const appTheme = createMuiTheme(theme);

//Moment Locale Configuration
moment.locale(userLocale);

// Firebase Provider Configurations
const rrfConfig = {
  userProfile: 'users'
};

const rrfProps = {
  config: rrfConfig,
  dispatch: store.dispatch,
  firebase: firebaseApp,
};

// ReactIntl Provider Configurations
addLocaleData([...locale_en, ...locale_fr]);

ReactDOM.render(
  <MuiThemeProvider theme={appTheme}>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
          <IntlProvider locale={userLocale} messages={appMessages[userLocale]}>
            <Router>
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={userLocale}>
                <App />
              </MuiPickersUtilsProvider>
            </Router>
        </IntlProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
