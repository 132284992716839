import moment from 'moment';

export const getTimeArray = (): string[] => {
  const timeArray = [];
  for (let i = 0; i <= 23; i++) {
    for (let j = 0; j <= 45; j += 15) {
      const hour = i < 10 ? `0${i}` : i;
      const minute = j < 15 ? `0${j}` : j;

      timeArray.push(`${hour}:${minute}`);
    }
  }

  return timeArray;
};

export const getDefaultISODateTime = (): string => {
  const today = new Date();
  const momentDate = moment(today).add(1, 'day');
  momentDate.set({h: 7, m: 0, s: 0});

  return momentDate.toISOString();
};

export const getCurrentISODateTime = (): string => {
  return moment().toISOString();
};
