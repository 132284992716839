import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FieldIcon from '@material-ui/icons/LocationOn';
import NotificationIcon from '@material-ui/icons/NotificationImportant';
import SubstanceIcon from '@material-ui/icons/Opacity';
import UserIcon from '@material-ui/icons/AccountBox';
import EmailRecipientIcon from '@material-ui/icons/Email';

import { ConfigureImportantMessageContainer } from '../ImportantMessage';

import { NavigationMenuItem } from './interfaces';

import * as routes from '../../constants/routes';

const drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      width: drawerWidth
    },
    drawerPaper: {
      justifyContent: 'space-between',
      position: 'static',
      width: drawerWidth
    },
    itemText: {
      paddingLeft: 0
    },
    link: {
      alignItems: 'center',
      display: 'flex',
      textDecoration: 'none'
    },
    root: {
      display: 'flex'
    },
    text: {
      fontSize: '0.9em'
    }
  });

const menuItems: NavigationMenuItem[] = [
  {
    icon: <NotificationIcon />,
    label: 'notifications.page.title',
    to: routes.ROOT
  },
  {
    icon: <FieldIcon />,
    label: 'fields.page.title',
    to: routes.FIELDS
  },
  {
    icon: <SubstanceIcon />,
    label: 'substances.page.title',
    to: routes.SUBSTANCES
  },
  {
    icon: <UserIcon />,
    label: 'users.page.title',
    to: routes.USERS
  },
  {
    icon: <EmailRecipientIcon />,
    label: 'emailRecipients.page.title',
    to: routes.EMAIL_RECIPIENTS
  },
];

const AdminNavigation: React.FC<WithStyles> = ({ classes }) => {
  return (
    <div className="AdminNavigation">
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          {menuItems.map((item: NavigationMenuItem) => {
            return (
              <ListItem key={item.to} button={true} component={Link} {...{ to: item.to } as any}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  className={classes.itemText}
                  primaryTypographyProps={{
                    className: classes.text
                  }}
                  primary={<FormattedMessage id={item.label} />}
                />
              </ListItem>
            );
          })}
        </List>
        <List>
          <ConfigureImportantMessageContainer />
        </List>
      </Drawer>
    </div>
  );
};

export default withStyles(styles)(AdminNavigation);
