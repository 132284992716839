import { DataTableOrder } from '../components/common/DataTable/interfaces';
import { OrderedFirebaseData } from '../firebase/interfaces';
import { DataTableEntityOptions } from './interfaces';

import { getCurrentISODateTime } from './dateTime';
import { naturalSort } from './sort';
import { DataTableAction } from '../constants/enums';

export const submitDataTableEntity = async <T>(options: DataTableEntityOptions<T>) => {
  const { action, entityName, firebase, inputs } = options;
  const actionName = Object.keys(action)[0];
  const entityKey = Object.values(action)[0];

  let op;
  switch (actionName) {
    case DataTableAction.ADD: {
      const data = {
        ...inputs,
        createdAt: getCurrentISODateTime(),
        isArchived: false
      };
      op = firebase.push(`/${entityName}`, data);
      break;
    }
    case DataTableAction.ARCHIVE: {
      const data = {
        archivedAt: getCurrentISODateTime(),
        isArchived: true
      };
      op = firebase.update(`/${entityName}/${entityKey}`, data);
      break;
    }
    case DataTableAction.EDIT: {
      const data = {
        ...inputs,
        modifiedAt: getCurrentISODateTime(),
      };
      op = firebase.update(`/${entityName}/${entityKey}`, data);
      break;
    }
    case DataTableAction.UNARCHIVE: {
      const data = {
        isArchived: false
      };
      op = firebase.update(`/${entityName}/${entityKey}`, data);
      break;
    }
  }

  try {
    return op;

  }
  catch (e) {
    return e;

  }
};

export const natSort = (array: any, order: DataTableOrder, orderBy: string) => {
  const filteredNames = array.map((a: any) => orderBy.split('.').reduce((acc: any, prop: string) => acc[prop], a.value) || a.value[orderBy]);
  const sortedIndices = order === 'desc' ? naturalSort(filteredNames).reverse() : naturalSort(filteredNames);
  return sortedIndices.map((idx: number) => array[idx]);
}

export const stableSort = (array: any, cmp: (a: any, b: any) => number) => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);

  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el: any) => el[0]);
};

export const getSorting = (order: DataTableOrder, orderBy: string) => {
  return order === 'desc' ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
};

const desc = (a: any, b: any, orderBy: string) => {
  if (isOrderedFirebaseData(a) && isOrderedFirebaseData(b)) {
    const valueA = orderBy.split('.').reduce((acc: any, prop: string) => acc[prop], a.value) || a.value[orderBy];
    const valueB = orderBy.split('.').reduce((acc: any, prop: string) => acc[prop], b.value) || b.value[orderBy];

    if (valueB < valueA) return -1;
    if (valueB > valueA) return 1;
  }
  else {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  }

  return 0;
};

const isOrderedFirebaseData = (data: any): data is OrderedFirebaseData => {
  return typeof data.value !== undefined;
};
