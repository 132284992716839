import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import moment from 'moment';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { NotificationActions } from './';

import { OrderedRemoteUserNotification } from './interfaces';

interface Props {
  handleArchiveClick?: () => void;
  handleArchiving: (id: string) => void;
  notification: OrderedRemoteUserNotification;
}

const styles = (theme: Theme) =>
  createStyles({
    field: {
      fontSize: '1.05em',
      fontWeight: 700,
      lineHeight: '1.3em'
    },
    fieldLabel: {
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: 'solid',
      borderBottomWidth: 3,
      paddingBottom: 3,
      textTransform: 'uppercase'
    },
    item: {
      display: 'flex',
      padding: 15,
      width: '100%'
    },
    label: {
      fontWeight: 700
    },
    substance: {
      fontSize: '0.9em'
    }
  });

const NotificationItem: React.FC<Props & InjectedIntlProps & WithStyles> = ({ classes, intl, notification: {key, value: {comments, dateTime, field, substance, targetCrop, treatmentComments}}, handleArchiveClick }) => {
  return (
    <li className="NotificationItem">
      <Paper className={classes.item}>
        <div className="NotificationData">
          <Typography className={classes.field} variant="h2">
            {field} {targetCrop ? `(${targetCrop})` : ''}
          </Typography>
          <Typography variant="caption" color="textPrimary">
            <span className={classes.label}><FormattedMessage id="notifications.dateTime.label" /></span>: {moment(dateTime).format(intl.formatMessage({id: 'app.dateTime.format'}))}
          </Typography>
          <Typography variant="caption" color="textPrimary">
            {treatmentComments}
          </Typography>
          <Typography className={classes.substance} variant="caption">
            {substance}
          </Typography>
          <Typography variant="caption" color="textPrimary">
            {comments}
          </Typography>
        </div>
        <div className="NotificationActions">
          <NotificationActions
            notificationKey={key}
            handleArchiveClick={handleArchiveClick}
          />
        </div>
      </Paper>
    </li>
  );
};

export default withStyles(styles)(injectIntl(NotificationItem));
