import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { FormattedMessage } from 'react-intl';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/icons/Announcement';

import { ConfigureImportantMessageForm } from './';
import { FormDialog } from '../common/FormDialog';
import { withAuthorization } from '../Session';

import { AppState } from '../../redux/reducers/interfaces';
import { AuthConditionProps } from '../Session/interfaces';
import { ValidationErrors } from '../../helpers/interfaces';
import { ImportantMessage } from './interfaces';

import { translateFirebaseError } from '../../helpers/errors';
import { validate } from '../../helpers/validation';
import validationRules from './importantMessageRules';
import { AuthContext, UserRole } from '../../constants/enums';

interface Props {
  firebase: {
    update: (path: string, data: object) => Promise<object>
  };
  motd: ImportantMessage;
}

interface State {
  globalError?: string;
  inputs: ImportantMessage;
  open: boolean;
  originalInputs: ImportantMessage;
  validationErrors?: ValidationErrors;
}

const styles = (theme: Theme) =>
  createStyles({
    itemText: {
      paddingLeft: 0
    },
    text: {
      fontSize: '0.9em'
    }
  });

class ConfigureImportantMessageContainer extends React.Component<Props & WithStyles, State> {
  readonly state: State = {
    inputs: {
      en: '',
      fr: ''
    },
    open: false,
    originalInputs: {
      en: '',
      fr: ''
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.motd && prevProps.motd !== this.props.motd) {
      this.setState({ inputs: this.props.motd, originalInputs: this.props.motd });
    }
  }

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [name]: event.target.value
      }
    });
  }

  closeDialog = () => {
    this.setState({ inputs: this.state.originalInputs, open: false, validationErrors: undefined });
  }

  dialogConfirm = async () => {
    this.setState({ globalError: undefined, validationErrors: undefined });
    const validationErrors = validate(this.state.inputs, validationRules);

    if (Object.keys(validationErrors).length) {
      this.setState({ validationErrors });
    }
    else {
      const { inputs } = this.state;
      try {
        await this.props.firebase.update('/motd', inputs);
        this.closeDialog();
      }
      catch (e) {
        console.error(e);
        this.setState({ globalError: translateFirebaseError(e) });
      }
    }
  }

  openDialog = () => {
    this.setState({ open: true });
  }

  render() {
    const { classes } = this.props;
    const { inputs, open, validationErrors } = this.state;
    return (
      <>
        <ListItem button={true} onClick={this.openDialog} component="li">
          <ListItemIcon><Icon /></ListItemIcon>
          <ListItemText
            className={classes.itemText}
            primaryTypographyProps={{
              className: classes.text
            }}
            primary={<FormattedMessage id="importantMessage.button.configure" />}
          />
        </ListItem>

        <FormDialog
          open={open}
          dialogCancelLabel={<FormattedMessage id="importantMessage.configure.dialog.button.cancel" />}
          dialogConfirmLabel={<FormattedMessage id="importantMessage.configure.dialog.button.confirm" />}
          dialogTitle={<FormattedMessage id="importantMessage.configure.dialog.title" />}
          handleClose={this.closeDialog}
          handleConfirm={this.dialogConfirm}
        >
          <ConfigureImportantMessageForm
            inputs={inputs}
            validationErrors={validationErrors}
            handleChange={this.handleChange}
          />
        </FormDialog>
      </>
    );
  }
}

const authCondition = (authUser: AuthConditionProps) => !authUser.profile.isEmpty && Object.keys(authUser.profile.roles).includes(UserRole.ADMIN);

const firebaseQuery = () => {
  return [
    { path: 'motd' },
  ];
};

const mapStateToProps = (state: AppState) => {
  return {
    motd: state.firebase.data.motd,
  };
};

export default compose(
  withStyles(styles),
  withAuthorization(authCondition, AuthContext.COMPONENT),
  connect(mapStateToProps),
  firebaseConnect(firebaseQuery),
)(ConfigureImportantMessageContainer);
