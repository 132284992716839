import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { CircularProgressLoader } from '../common/Progress';

import { FirebaseLoaderInjectedProps } from './interfaces';
import { AppState } from '../../redux/reducers/interfaces';

type LoaderEntities = string[];

interface State {
  displayLoader: boolean;
  isDoneLoading: boolean;
}

const withFirebaseLoader = (loaderEntities: LoaderEntities) => <WrappedProps extends FirebaseLoaderInjectedProps>(Component: React.ComponentType<WrappedProps>) => {
  class WithFirebaseLoader extends React.PureComponent<WrappedProps & FirebaseLoaderInjectedProps, State> {
    readonly state: State = {
      displayLoader: true,
      isDoneLoading: false
    };

    componentDidUpdate(prevProps: WrappedProps & FirebaseLoaderInjectedProps) {
      if (prevProps.requesting !== this.props.requesting) {
        const { requesting } = this.props;
        const regEx = new RegExp(loaderEntities.join('|'));
        const keyMatches = Object.keys(requesting).reduce((keyAcc: string[], key: string) => {
          if (key.match(regEx) != null) {
            return [...keyAcc, key];
          }

          return keyAcc;
        }, []);
        const displayLoader = !Object.keys(requesting).length || keyMatches.some((key: string) => requesting[key]);

        this.setState({ displayLoader });

        if (displayLoader === false) {
          this.setState({ isDoneLoading: true });
        }
      }
    }

    render() {
      if (this.state.displayLoader && !this.state.isDoneLoading) {
        return (
          <CircularProgressLoader />
        );
      }
      else {
        return (
          <Component {...this.props} />
        );
      }
    }
  }

  const mapStateToProps = (state: AppState) => {
    return {
      requesting: state.firebase.requesting
    };
  };

  return compose(
    connect(mapStateToProps)
  )(WithFirebaseLoader as any);
};

export default withFirebaseLoader;
