import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';

interface Props {
  children: React.ReactNode;
  to: string;
}

const styles = (theme: Theme) =>
  createStyles({
    back: {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      marginLeft: -15
    }
  });

const Back: React.FC<Props & WithStyles> = ({ children, classes, to }) => {
  return (
    <Button
      className={classes.back}
      component={Link}
      {...{ to } as any}
    >
      <BackIcon />
      {children}
    </Button>
  );
}

export default withStyles(styles)(Back);
