import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { CircularProgressLoader } from '../common/Progress';
import { AuthRouting, NonAuthRouting } from '../Routing';

import { AppState } from '../../redux/reducers/interfaces';

import { UserRole } from '../../constants/enums';

import './App.scss';

interface Props extends RouteComponentProps {
  authExists: boolean;
  isAdmin: boolean;
  isLoading: boolean;
}

class App extends React.Component<Props> {
  render() {
    const { authExists, isAdmin, isLoading } = this.props;

    return (
      <div className="App">
        <CircularProgressLoader isLoading={isLoading}>
          {authExists ? (
            <AuthRouting isAdmin={isAdmin}  />
          ) : (
            <NonAuthRouting />
          )}
        </CircularProgressLoader>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    authExists: !!state.firebase.auth && !!state.firebase.auth.uid,
    isAdmin: !!state.firebase.profile.roles && Object.keys(state.firebase.profile.roles).includes(UserRole.ADMIN),
    isLoading: !state.firebase.auth.isLoaded || !state.firebase.profile.isLoaded
  };
};

export default withRouter(connect(mapStateToProps)(App));
