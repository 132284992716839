import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  dialogCancelLabel: React.ReactNode;
  dialogConfirmLabel: React.ReactNode;
  dialogMessage: React.ReactNode;
  dialogTitle: React.ReactNode;
  handleClose: () => void;
  handleConfirm: () => void | Promise<void>;
  open: boolean;
}

const ConfirmationDialog: React.FC<Props> = ({ dialogCancelLabel, dialogConfirmLabel, dialogMessage, dialogTitle, handleClose, handleConfirm, open }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {dialogCancelLabel}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus={true}>
          {dialogConfirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
