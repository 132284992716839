import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { DataTableAction } from '../../../constants/enums';

interface Props {
  entityKey: string;
  handleDialog: (action: DataTableAction, entityKey: string) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  isArchived: boolean;
}

const DataTableActions: React.FC<Props> = ({ entityKey, handleDialog, isArchived }) => {
  const archiveButtonAction = isArchived ? DataTableAction.UNARCHIVE : DataTableAction.ARCHIVE;
  const archiveButtonLabel = isArchived ? 'dataTable.actions.unarchive.label' : 'dataTable.actions.archive.label';

  return (
    <div className="DataTableActions">
      <Button size="small" onClick={handleDialog(DataTableAction.EDIT, entityKey)}>
        <EditIcon fontSize="small" /> <FormattedMessage id="dataTable.actions.edit.label" />
      </Button>
      <Button size="small" onClick={handleDialog(archiveButtonAction, entityKey)}>
        <DeleteIcon fontSize="small" /> <FormattedMessage id={archiveButtonLabel} />
      </Button>
    </div>
  );
};

export default DataTableActions;
