import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';

import { DataTableCol, DataTableOrder } from './interfaces';

interface Props {
  cols: DataTableCol[];
  order: DataTableOrder;
  orderBy: string;
  onRequestSort: (event: React.SyntheticEvent, property: string) => void;
}

const SortableTableHead: React.FC<Props> = ({ cols, onRequestSort, order, orderBy }) => {
  const createSortHandler = (property: string) => (event: React.SyntheticEvent) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {cols.map(
          (col: DataTableCol) => (
            <TableCell
              key={col.id}
              align={col.numeric ? 'right' : 'left'}
              padding={col.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === col.id ? order : false}
            >
              {col.sortable ? (
                <Tooltip
                  title={<FormattedMessage id="dataTable.sort.label" />}
                  placement={col.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === col.id}
                    direction={order}
                    onClick={createSortHandler(col.id)}
                  >
                    {col.label}
                  </TableSortLabel>
                </Tooltip>
              ) : (
                col.label
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default SortableTableHead;
