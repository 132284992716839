import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import 'whatwg-fetch';

import { EmailRecipientForm } from './';
import { ActionsTableCell, DataTable, DataTableActions } from '../common/DataTable';
import { MobileTitle } from '../common/Layouts';
import { withFirebaseLoader } from '../Loader';
import { withAuthorization } from '../Session';

import { DataTableActionState, DataTableCol, DataTableOrder } from '../common/DataTable/interfaces';
import { OrderedRemoteEmailRecipient, EmailRecipient } from './interfaces';
import { AuthConditionProps } from '../Session/interfaces';
import { AppState } from '../../redux/reducers/interfaces';
import { ValidationErrors } from '../../helpers/interfaces';

import { translateFirebaseError } from '../../helpers/errors';
import { submitDataTableEntity, stableSort, getSorting } from '../../helpers/dataTable';
import { validate } from '../../helpers/validation';
import validationRules from './emailRecipientRules';
import { DataTableAction, UserRole } from '../../constants/enums';

interface Props {
  emailRecipients: OrderedRemoteEmailRecipient[];
  firebase: {
    push: (path: string, data: object) => Promise<object>
    update: (path: string, data: object) => Promise<object>
  };
}

interface State {
  globalError?: string;
  inputs: EmailRecipient;
  validationErrors?: ValidationErrors;
}

class EmailRecipientsContainer extends React.Component<Props & InjectedIntlProps, State> {
  static readonly inputsInitialState: EmailRecipient = {
    email: ''
  };

  static readonly cols: DataTableCol[] = [
    { id: 'email', sortable: true, numeric: false, disablePadding: false, label: <FormattedMessage id="emailRecipients.dataTable.header.email" /> },
    { id: 'actions', sortable: false, numeric: false, disablePadding: false, label: <FormattedMessage id="dataTable.header.actions" /> },
  ];

  readonly state: State = {
    inputs: EmailRecipientsContainer.inputsInitialState,
  };

  closeDialog = () => {
    this.setState({
      inputs: EmailRecipientsContainer.inputsInitialState,
      validationErrors: undefined
    });
  }

  confirmDialog = async (dataTableAction: DataTableActionState) => {
    this.setState({ globalError: undefined, validationErrors: undefined });
    const { inputs } = this.state;
    const { emailRecipients } = this.props;
    const validationErrors = validate(inputs, validationRules);
    if (Object.keys(validationErrors).length === 0 && emailRecipients.some(emailRecipient => emailRecipient.value.email === inputs.email)) {
      validationErrors.email = { id: 'emailRecipients.validation.isUnique' };
    }
    const action = Object.keys(dataTableAction)[0];

    if ((action !== DataTableAction.ARCHIVE && action !== DataTableAction.UNARCHIVE) && Object.keys(validationErrors).length) {
      this.setState({ validationErrors });
      throw Error('validation errors');
    }
    else {
      const { firebase } = this.props;
      const options = {
        firebase,
        inputs,
        action: dataTableAction,
        entityName: 'emailRecipients',
      };

      try {
        await submitDataTableEntity<EmailRecipient>(options);
      }
      catch (e) {
        console.error(e);
        this.setState({ globalError: translateFirebaseError(e) });
        throw(e);
      }
    }
  }

  openDialog = (action: string, entityKey?: string) => (_0: React.MouseEvent<HTMLButtonElement>) => {
    const { emailRecipients } = this.props;

    if (action === DataTableAction.EDIT) {
      const emailRecipient = emailRecipients.find((s: OrderedRemoteEmailRecipient) => s.key === entityKey);
      if (emailRecipient) {
        this.setState({ inputs: emailRecipient.value });
      }
    }
  }

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [name]: event.target.value
      }
    });
  }

  render() {
    const { emailRecipients } = this.props;
    const { inputs, validationErrors } = this.state;

    const emailRecipientForm = () => (
      <EmailRecipientForm
        inputs={inputs}
        validationErrors={validationErrors}
        handleChange={this.handleChange}
      />
    );

    return (
      <div className="EmailRecipientsContainer">
        <MobileTitle withLogout={true}>
          <FormattedMessage id="emailRecipients.page.title" />
        </MobileTitle>
        <DataTable
          addButtonLabel={<FormattedMessage id="emailRecipients.button.add" />}
          addEditForm={emailRecipientForm}
          addDialogTitle={<FormattedMessage id={'emailRecipients.adding.dialog.title'} />}
          archiveDialogCancelLabel={<FormattedMessage id="emailRecipients.archive.dialog.button.cancel" />}
          archiveDialogConfirmLabel={<FormattedMessage id="emailRecipients.archive.dialog.button.confirm" />}
          archiveDialogMessage={<FormattedMessage id="emailRecipients.archive.dialog.message" />}
          archiveDialogTitle={<FormattedMessage id="emailRecipients.archive.dialog.title" />}
          unarchiveDialogCancelLabel={<FormattedMessage id="emailRecipients.unarchive.dialog.button.cancel" />}
          unarchiveDialogConfirmLabel={<FormattedMessage id="emailRecipients.unarchive.dialog.button.confirm" />}
          unarchiveDialogMessage={<FormattedMessage id="emailRecipients.unarchive.dialog.message" />}
          unarchiveDialogTitle={<FormattedMessage id="emailRecipients.unarchive.dialog.title" />}
          editDialogTitle={<FormattedMessage id="emailRecipients.editing.dialog.title" />}
          cols={EmailRecipientsContainer.cols}
          orderBy="email"
          rowCount={emailRecipients.length}
          handleDialogClose={this.closeDialog}
          handleDialogConfirm={this.confirmDialog}
          handleDialogOpen={this.openDialog}
        >
          {(order: DataTableOrder, orderBy: string, page: number, rowsPerPage: number, openDialog: any) => {
            return (
              stableSort(emailRecipients, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n: OrderedRemoteEmailRecipient) => {
                return (
                  <TableRow
                    className={n.value.isArchived ? 'TableRow-archived' : ''}
                    classes={{hover: 'TableRow-hover'}}
                    hover={true}
                    tabIndex={-1}
                    key={n.key}
                  >
                    <TableCell>{n.value.email}</TableCell>
                    <ActionsTableCell>
                      <DataTableActions
                        entityKey={n.key}
                        handleDialog={openDialog}
                        isArchived={n.value.isArchived}
                      />
                    </ActionsTableCell>
                  </TableRow>
                );
              })
            );
          }}
        </DataTable>
      </div>
    );
  }
}

const firebaseQuery = (props: RouteComponentProps) => {
  const params = new URLSearchParams(props.location.search);
  const includeArchived = params.get('includeArchived') || false;
  const queryParams = includeArchived ? [] : ['parsed', 'orderByChild=isArchived', 'equalTo=false'];

  return [
    { path: 'emailRecipients', queryParams },
  ];
};

const mapStateToProps = (state: AppState) => {
  return {
    emailRecipients: state.firebase.ordered.emailRecipients || [],
  };
};

const authCondition = (authUser: AuthConditionProps) => Object.keys(authUser.profile.roles).includes(UserRole.ADMIN);
const loaderEntities = ['emailRecipients'];

export default compose(
  injectIntl,
  withAuthorization(authCondition),
  connect(mapStateToProps),
  firebaseConnect(firebaseQuery),
  withFirebaseLoader(loaderEntities),
)(EmailRecipientsContainer);
